import {
  Row,
  Col,
  Table,
  Avatar,
  DatePicker,
  DatePickerProps,
  theme,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  Typography,
  TimePicker,
  Tag,
} from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { EmployeeOperation } from "../../../serviceoperation/EmployeeOperation";
import { CompanyContext } from "../../../contexts/company-context";
import dayjs from "dayjs";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import {
  BulkScheduleList,
  AddBulkScheduleFormProps,
} from "./schedule-bulkadd-entity";
import {
  EmployeeDetail,
  EmployeeDetails,
} from "../../../serviceoperation/entity/EmployeeOperationEntity";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { getInitials } from "../../../Util/stringutil";
import { ScheduleOperation } from "../../../serviceoperation/ScheduleOperation";
import { ScheduleList } from "../../../serviceoperation/entity/ScheduleOperationEntity";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "date" | "dropdown";
  record: BulkScheduleList;
  index: number;
  children: React.ReactNode;
}

const ScheduleBulkAdd: React.FC<AddBulkScheduleFormProps> = ({
  scheduleData,
  employeeData,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<BulkScheduleList[]>(scheduleData);
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState<number>(-1);
  const userInfo = useContext(AuthenticationContext)?.userInfo;

  // User and Company Context.
  const { selectedCompany } = useContext(CompanyContext);
  const { useToken } = theme;
  const { token } = useToken();

  const currDate = new Date();

  // Selected Week Schedule Constants.
  const [selectedWeek, setSelectedWeek] = useState<string[]>([]);
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  // List of employee for a given logged in user.
  // This will contain a data regardless of who is logged in
  // as a logged in user will be in this list.
  const [employee, setEmployee] = useState<any[]>([]);
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails[]>([]);

  // Get the Current Week Dates based on any Date
  // This is to set the Table view Dynamically.
  // Make sure to add the offset of the
  const handleWeekChange = (selWeek: string) => {
    const current = new Date(selWeek);
    // So that this doesn't mess with the SetDate.
    current.setHours(0, 0, 0, 0);
    const week = [];
    for (let i = 0; i < 7; i++) {
      const first = current.getDate() - current.getDay() + i;
      const day = new Date(current.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }
    setSelectedWeek(week);

    // This will remove the data that was fetched from the server
    // meaning data that can't be modified because the scedule already
    // exists.
    setData(data.filter((x) => !x.existingRow));

    // Process UseEffect call to get new Schedule... How to best do that?
    // Need to fetch existing schedule for the given week so that we can render the data...
  };

  useEffect(() => {
    handleWeekChange(currDate.toISOString());
  }, []);

  const employeeOps = useMemo(() => new EmployeeOperation(), []);

  useMemo(() => {
    const empId = userInfo?.employeeCorporation
      ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
      .map((x) => x.employeeId)
      .at(0);
    const getEmployee = async () => {
      if (!selectedCompany) return;
      const employees = await employeeOps.getEmployeeAsync(
        selectedCompany.corporationId,
        1,
        255
      );
      setEmployeeDetails(
        employees.results.filter(
          (employee) => employee.employeeId !== empId && employee.isActive
        )
      );
      setEmployee(
        employees.results
          .filter(
            (employee) => employee.employeeId !== empId && employee.isActive
          )
          .map((employee) => ({
            value: employee.employeeId,
            label: `${employee.firstName} ${employee.lastName}`,
          }))
      );
    };
    getEmployee();
  }, [selectedCompany, employeeOps]);

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const isDisabled = () => {
      // Access the specific schedule array based on dataIndex
      // and check if the edit should be disabled or not.
      if (dataIndex.startsWith("schedule")) {
        const scheduleKey = dataIndex as keyof BulkScheduleList;
        const scheduleValue = record[scheduleKey] as
          | [dayjs.Dayjs, dayjs.Dayjs, boolean]
          | undefined;
        if (scheduleValue && scheduleValue[2]) {
          return true;
        }
      }
      return false;
    };

    const inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : inputType === "date" ? (
        <TimePicker.RangePicker
          minuteStep={30}
          format="hh:mm A"
          disabled={isDisabled()}
        />
      ) : inputType === "dropdown" ? (
        <Select
          disabled={!record.newRow}
          placeholder="Select Employee"
          key={record.key}
          value={record.name}
          options={employee.filter(
            (emp) => !data.find((da) => emp.value === da.name)
          )}
        />
      ) : (
        <Input />
      );
    return (
      <td {...restProps}>
        {editing && inputType !== "date" ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : editing && inputType === "date" ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            //rules={[
            //  {
            //    required: true,
            //    validator: validateDatetime(record.key),
            //  },
            //]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record: Partial<BulkScheduleList> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
    setDisableAdd(true);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as BulkScheduleList;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      const item = newData[index];
      if (index > -1) {
        //const item = newData[index];
        // Set Row to not new data...
        item.newRow = false;
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey(-1);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey(-1);
      }
      // This is a call back to Parent so that it can have
      // all the data in the world.
      onSuccess(newData, employee, employeeDetails, selectedWeek);

      setDisableAdd(false);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  function convertResultsToBulkScheduleList(
    schedule: ScheduleList
  ): BulkScheduleList[] {
    const employeeMap = new Map<string, BulkScheduleList>();

    schedule.results.forEach((result) => {
      const {
        employee,
        workDate,
        startWorkHour,
        startWorkMinute,
        endWorkHour,
        endWorkMinute,
      } = result;
      const employeeId = employee.employeeID;

      // Create dayjs objects for the start and end times
      const start = dayjs(workDate)
        .set("hour", startWorkHour)
        .set("minute", startWorkMinute);
      const end = dayjs(workDate)
        .set("hour", endWorkHour)
        .set("minute", endWorkMinute);

      // If the employee is not already in the map, add them with default values
      if (!employeeMap.has(employeeId)) {
        employeeMap.set(employeeId, {
          key: Date.now() + (Math.floor(Math.random() * (100 - 1 + 1)) + 1),
          name: employeeId,
          schedulesunday: undefined,
          schedulemonday: undefined,
          scheduletuesday: undefined,
          schedulewednesday: undefined,
          schedulethursday: undefined,
          schedulefriday: undefined,
          schedulesaturday: undefined,
          newRow: false,
          existingRow: true,
        });
      }

      const schedule = employeeMap.get(employeeId)!;
      // Get the day of the week (0 = Sunday, 6 = Saturday)
      const dayOfWeek = start.day();

      // Update the schedule for the correct day of the week
      switch (dayOfWeek) {
        case 0:
          schedule.schedulesunday = [start, end, true];
          break;
        case 1:
          schedule.schedulemonday = [start, end, true];
          break;
        case 2:
          schedule.scheduletuesday = [start, end, true];
          break;
        case 3:
          schedule.schedulewednesday = [start, end, true];
          break;
        case 4:
          schedule.schedulethursday = [start, end, true];
          break;
        case 5:
          schedule.schedulefriday = [start, end, true];
          break;
        case 6:
          schedule.schedulesaturday = [start, end, true];
          break;
      }
    });
    return Array.from(employeeMap.values());
  }

  useEffect(() => {
    const scheduleOps = new ScheduleOperation();
    scheduleOps
      .getScheduleAsync(selectedCompany?.corporationId as number, {
        weekOf: selectedWeek[0],
        page: 1,
        pageSize: 255,
      })
      .then((x) => {
        const rows = convertResultsToBulkScheduleList(x);
        const newData = [...data];
        rows.forEach((row) => {
          // This is the index where the Employee Exsists on a given table.
          // So on this case let's just look at the current data.
          const index = newData.findIndex((item) =>
            newData.find((data) => row.name === item.name)
          );

          const item = newData[index];
          if (index > -1) {
            //const item = newData[index];
            // Set Row to not new data...
            item.newRow = false;
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            //setEditingKey(-1);
          } else {
            newData.push(row);
            //setEditingKey(-1);
          }
        });
        setData(newData);
      });
  }, [selectedWeek]);

  const cancel = (value?: BulkScheduleList) => {
    setEditingKey(-1);
    if (value?.newRow) {
      const updatedData = [...data].filter((item) => item.key !== value.key);
      setData(updatedData);
    }
    setDisableAdd(false);
  };

  const handleAdd = () => {
    const newData = {
      key: Date.now(),
      name: "",
      schedulesunday: undefined,
      schedulemonday: undefined,
      scheduletuesday: undefined,
      schedulewednesday: undefined,
      schedulethursday: undefined,
      schedulefriday: undefined,
      schedulesaturday: undefined,
      newRow: true,
      existingRow: false,
    };
    const updatedData = [...data, newData];
    setData(updatedData);
    edit(newData);
  };

  const Dayscolumns = daysOfWeek.map((day, index) => ({
    title: `${new Date(selectedWeek[index]).toLocaleDateString("en-us", {
      timeZone: "UTC",
      weekday: "short",
      month: "short",
      day: "numeric",
    })}`,
    dataIndex: `schedule${day}`,
    //width: "12%",
    editable: true,
    render: (_: any, record: BulkScheduleList) => {
      const dataIndex = `schedule${day}`;
      const daySchedule = eval(`record.${dataIndex}`); // Using eval to access the dynamic property
      if (daySchedule) {
        const startTime = dayjs(daySchedule[0]);
        const endTime = dayjs(daySchedule[1]);
        return (
          <Tag color="orange">
            {`${dayjs(startTime)?.format("hh:mm A")} - ${dayjs(endTime)?.format(
              "hh:mm A"
            )}`}
          </Tag>
        );
      }
      return null;
    },
  }));

  const columns = [
    {
      title: "",
      dataIndex: "name",
      width: "10%",
      editable: true,
      render(_: any, record: BulkScheduleList) {
        return (
          <div>
            <Avatar
              style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
              size="default"
              gap={4}
            >
              {getInitials(
                employee
                  .filter((x) => x.value === _)
                  .map((map) => map.label)
                  .at(0)
              )}
            </Avatar>
            &nbsp;
            {employee
              .filter((x) => x.value === _)
              .map((map) => map.label)
              .at(0)}
          </div>
        );
      },
    },
    ...Dayscolumns,
    {
      title: "Action",
      dataIndex: "operation",
      width: "5%",
      editable: false,
      render: (_: any, record: BulkScheduleList) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Are you sure?" onConfirm={() => cancel(record)}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== -1}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const isEditing = (record: BulkScheduleList) => record.key === editingKey;

  const onWeekChange: DatePickerProps["onChange"] = (date) => {
    // Have to set the Time of the day as well, otherwise it offsets the day by 1.
    //handleWeekChange(currDate.toDateString());
    handleWeekChange(date?.toISOString() as string);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: BulkScheduleList) => ({
        record,
        inputType: col.dataIndex.includes("day")
          ? "date"
          : col.dataIndex === "name"
          ? "dropdown"
          : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <div style={{ backgroundColor: token.colorBgContainer }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={18} style={{ padding: 20 }}>
            <Space size={6}>
              <DatePicker
                defaultValue={dayjs(currDate)}
                onChange={onWeekChange}
                picker="week"
                disabledDate={(current) => {
                  const customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
              />
            </Space>
          </Col>
        </Row>
      </div>
      <Row gutter={16} style={{ marginTop: "32px" }}>
        <Col span={24}>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              style={{ whiteSpace: "pre" }}
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
            />
            <Button
              type="primary"
              onClick={handleAdd}
              disabled={disableAdd}
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              <PlusOutlined /> Add Employee
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleBulkAdd;
