enum LoadingState {
  TokenReady,
  Loading,
  Unauthorized,
  Error,
  Inactive,
  Done,
  NoActiveCorp,
}

export default LoadingState;
