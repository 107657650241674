import {
  Form,
  Input,
  Typography,
  Row,
  Button,
  Col,
  DatePicker,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { MetadataOperation } from "../../serviceoperation/MetadataOperation";
import { Preference } from "../../serviceoperation/entity/MetadataOperationEntity";
import { NewCorpEntity } from "./corp-new-entity";

interface Values {
  employee: string;
  title: string;
  note: string;
  modifier: string;
  timeZone: string;
  location: string;
}

interface AddCorpMetadataFormProps {
  newCorpData: NewCorpEntity;
  onCreate: (values: NewCorpEntity) => void;
}

//AddScheduleForm
const AddCorpMetadataForm: React.FC<AddCorpMetadataFormProps> = ({
  newCorpData,
  onCreate,
}) => {
  const [form] = Form.useForm();
  const [metadata, setMetadata] = useState<Preference[]>();
  useEffect(() => {
    const metadata = new MetadataOperation();
    metadata.getMetadataAsync("CORP").then((x) => {
      if (x.statusCode == 200 && x.data) {
        setMetadata(x.data);
      }
    });
  }, []);

  useEffect(() => {
    // Prepare initial values dynamically from newCorpData
    const initialValues: { metadata: Record<string, any> } = {
      metadata: {},
    };

    metadata?.forEach((field) => {
      field.preferenceMetadata.forEach((meta) => {
        // Set default to true for boolean types
        if (meta.metaDataType === "bool") {
          initialValues.metadata[meta.metaDataId] = true; // Always set to true
        }
      });
    });

    form.setFieldsValue(initialValues);
  }, [form, newCorpData, metadata]);

  return (
    <Form
      layout="vertical"
      /*onFinish={onFinish}*/ style={{ margin: "40px" }}
      form={form}
      name="metadata"
      initialValues={newCorpData}
    >
      {metadata?.map((field, i) => {
        return (
          <>
            <Typography.Title level={5}>
              {field.preferenceName}
            </Typography.Title>
            <Typography.Paragraph>
              {field.preferenceDescription}
            </Typography.Paragraph>

            {field.preferenceMetadata.map((meta, i) => {
              return (
                <Row gutter={[10, 4]}>
                  <Col md={14} xs={24}>
                    <Form.Item
                      name={["metadata", `${meta.metaDataId}`]}
                      label={`${meta.metaDescription}`}
                      key={i}
                      rules={[
                        {
                          required: true,
                          message:
                            meta.metaDataType === "int"
                              ? `${meta.metaDescription} is required and must be a number`
                              : `${meta.metaDescription} is required.`,
                          pattern:
                            meta.metaDataType === "int"
                              ? new RegExp(/^[0-9]+$/)
                              : new RegExp(/^/),
                        },
                      ]}
                      //htmlFor={`${field.name}`}
                    >
                      {meta.metaDataName.includes("_DATE") ? (
                        <DatePicker id={`${meta.metaDataId}`} />
                      ) : meta.metaDataType === "bool" ? (
                        <Switch
                          id={`${meta.metaDataId}`}
                          checked={form.getFieldValue([
                            "metadata",
                            `${meta.metaDataId}`,
                          ])}
                          onChange={(checked) =>
                            form.setFieldsValue({
                              metadata: { [meta.metaDataId]: checked },
                            })
                          }
                        />
                      ) : (
                        <Input
                          id={`${meta.metaDataId}`}
                          placeholder={meta.metaDescription}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      })}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            form
              // Validate the required fields.
              .validateFields()
              .then(async (values) => {
                const a = newCorpData;
                a.metadata = values.metadata;
                // Insert Employee Schedule..
                // This is where you insert Schedule
                // and then return to Parent Component. :)
                onCreate(a);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Next
        </Button>
      </Form.Item>
      {/*<Form.Item noStyle shouldUpdate>
        {() => (
          <Typography>
            <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
          </Typography>
        )}
      </Form.Item>*/}
    </Form>
  );
};

export default AddCorpMetadataForm;
