import { SwapOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Checkbox,
  Empty,
  List,
  Modal,
  Skeleton,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { ScheduleReplacementOperation } from "../../../serviceoperation/ScheduleReplacementOperation";
import { CompanyContext } from "../../../contexts/company-context";
import { AmPMFormat, ConvertTo12hr } from "../../../Util/dateutil";
import { getInitials } from "../../../Util/stringutil";
import { ScheduleDetails } from "../../../serviceoperation/entity/ScheduleOperationEntity";

interface ScheduleMatchList {
  id: number;
  avatar: string;
  title: string;
  description: string;
}

interface FindScheduleReplacementProps {
  open: boolean;
  schedule: ScheduleDetails;
  onCreate: (success: boolean) => void;
  onCancel: () => void;
}
const FindScheduleReplacement: React.FC<FindScheduleReplacementProps> = ({
  open,
  schedule,
  onCreate,
  onCancel,
}) => {
  const [showError, setShowError] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ScheduleMatchList[]>([]);
  const { Title, Paragraph } = Typography;
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {
    const scheduleReplacementOps = new ScheduleReplacementOperation();
    const fetchData = async () => {
      setShowError(false);
      try {
        await scheduleReplacementOps
          .getFindScheduleReplacementAsync(
            selectedCompany?.corporationId as number,
            schedule.scheduleID
          )
          .then((value) => {
            setData(
              value.data?.map((x) => ({
                id: x.scheduleID,
                avatar: getInitials(
                  `${x.employee.firstName} ${x.employee.lastName}`
                ),
                title: `${new Date(x.workDate).toDateString()}, ${ConvertTo12hr(
                  x.startWorkHour
                )}:${x.startWorkMinute}${AmPMFormat(
                  x.startWorkHour
                )} - ${ConvertTo12hr(x.endWorkHour)}:${
                  x.endWorkMinute
                }${AmPMFormat(x.endWorkHour)}`,
                description: `${x.employee.firstName} ${x.employee.lastName}, ${x.branch.name}`,
              })) || []
            );
          });
      } catch (error) {
        // Handle error
        setMessage(
          "An error occurred while attempting to retrieve potential matches. Kindly attempt the operation again later."
        );
        setShowError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCompany]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleCheckboxChange = (itemId: number) => {
    const updatedSelection = [...selectedItems];
    const index = updatedSelection.indexOf(itemId);

    if (index === -1) {
      updatedSelection.push(itemId);
    } else {
      updatedSelection.splice(index, 1);
    }

    setSelectedItems(updatedSelection);
  };

  const handleOkClick = async () => {
    if (selectedItems.length == 0) {
      setShowError(true);
      setMessage(
        "Please pick at least one swap option to move forward with your request!"
      );
      return;
    }
    const scheduleReplacementOps = new ScheduleReplacementOperation();
    await scheduleReplacementOps
      .postScheduleReplacementAsync(
        selectedCompany?.corporationId as number,
        schedule.scheduleID,
        selectedItems.at(0) as number
      )
      .then((x) => {
        if (x.statusCode != undefined && x.statusCode.toString() === "201") {
          onCreate(true);
        }
        setShowError(true);
        setMessage(
          "An error occurred while attempting to retrieve potential matches. Kindly attempt the operation again later."
        );
      });
  };

  return schedule ? (
    <Modal
      open={open}
      title="Swap Schedule"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOkClick}
    >
      {showError ? <Alert message={message} type="error" showIcon /> : null}

      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        <Avatar style={{ marginRight: 16 }}>
          {getInitials(
            `${schedule.employee.firstName} ${schedule.employee.lastName}`
          )}
        </Avatar>
        <div>
          <Title level={5}>{`${new Date(
            schedule.workDate
          ).toDateString()}, ${ConvertTo12hr(schedule.startWorkHour)}:${
            schedule.startWorkMinute
          }${AmPMFormat(schedule.startWorkHour)} - ${ConvertTo12hr(
            schedule.endWorkHour
          )}:${schedule.endWorkMinute}${AmPMFormat(
            schedule.endWorkHour
          )}`}</Title>
          <div>{`${schedule.employee.firstName} ${schedule.employee.lastName}, ${schedule.branch.name}`}</div>
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "24px" }}>
        <SwapOutlined style={{ fontSize: "24px" }} />
      </div>
      <Paragraph>Potential Swap</Paragraph>
      <div>
        {loading ? (
          <Skeleton active />
        ) : data.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Checkbox
                    onChange={() => handleCheckboxChange(item.id)}
                    checked={selectedItems.includes(item.id)}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar> {item.avatar}</Avatar>}
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            imageStyle={{ height: 60 }}
            description={<span>No potential matches found</span>}
          />
        )}
      </div>
    </Modal>
  ) : null;
};

export default FindScheduleReplacement;
