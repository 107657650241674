export function calculateTotalHours(startTime: string, endTime: string): number {
    // Parse the start and end times into Date objects
    const startParts: string[] = startTime.split(":");
    const endParts: string[] = endTime.split(":");
  
    const startDate: Date = new Date();
    startDate.setHours(parseInt(startParts[0], 10), parseInt(startParts[1], 10), 0, 0);
  
    const endDate: Date = new Date();
    endDate.setHours(parseInt(endParts[0], 10), parseInt(endParts[1], 10), 0, 0);
  
    // Calculate the time difference
    const timeDifference: number = endDate.getTime() - startDate.getTime();
  
    // Calculate hours and minutes from the time difference
    const hours: number = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes: number = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
    // Calculate total hours
    const totalHours: number = hours + minutes / 60;
  
    return totalHours;
  }
  