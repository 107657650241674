import { Card, Col, Divider, Row, Space, Switch, Typography } from "antd";
import Meta from "antd/es/card/Meta";

const Notification = () => {
  const { Text, Paragraph } = Typography;

  return (
    <Row gutter={[16, 24]}>
      <Col
        span={12}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 24, order: 1 }}
        lg={{ span: 18, order: 0 }}
        xl={{ span: 18, order: 0 }}
      >
        <Card size="default">
          <Meta
            title="Notification from us"
            description="We may still send you important notifications about your account outside of your notification settings."
          />
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Meta
                title="Comments"
                description="These are notifications for comments on your posts and replies to your comments."
              />
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <div>
                  <Switch defaultChecked /> Push
                </div>
                <div>
                  <Switch defaultChecked /> Email
                </div>
                <div>
                  <Switch defaultChecked /> SMS
                </div>
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Meta
                title="Reminders"
                description="These are notifications to remind you of your upcoming schedule."
              />
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <div>
                  <Switch defaultChecked /> Push
                </div>
                <div>
                  <Switch defaultChecked /> Email
                </div>
                <div>
                  <Switch defaultChecked /> SMS
                </div>
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col
        span={12}
        xs={{ span: 0, order: 0 }}
        sm={{ span: 0, order: 0 }}
        md={{ span: 0, order: 0 }}
        lg={{ span: 6, order: 2 }}
        xl={{ span: 6, order: 2 }}
      />
    </Row>
  );
};

export default Notification;
