import { Avatar, Card, Col, Menu, Row, Space, Tag } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import Title from "antd/es/typography/Title";
import { PageContainer } from "@ant-design/pro-layout";
import { CorporationOperation } from "../../../serviceoperation/CorporationOperation";
import { CorpDetailAPIEntity } from "../../../serviceoperation/entity/CorporationOperationEntity";
import OrgLocations from "./orgLocations";
import CorpMetadataForm from "./orgMetadata";
import UnderConstruction from "../../../errors/under-construction";
import OrgTimeOffReasons from "./orgTimeOffReasons";
import OrgSubscription from "./orgSubscription";

const OrgLayout = () => {
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { selectedCompany } = useContext(CompanyContext);
  const [corpProfile, setCorpProfile] = useState<CorpDetailAPIEntity>();
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const defaultSelectedKey = "profileDetail";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);
  const [items, setItems] = useState<any>([
    {
      label: "Profile",
      key: "profileDetail",
    },
    {
      label: "Branches",
      key: "branches",
    },
    {
      label: "Time Off",
      key: "timeOff",
    },
    {
      label: "Subscription",
      key: "Subscription",
    },
  ]);

  const handleTabChange = (event: any) => {
    setCurrentTab(event.key);
  };

  useMemo(() => {
    const employeeOps = new CorporationOperation();
    const loggedInUserID = userInfo?.employeeCorporation
      ?.filter((y) => y.corporationId === selectedCompany?.corporationId)
      .map((y) => y.employeeId)
      .at(0);
    //CurrentlY Logged In user same as the Schedule Id User??

    employeeOps
      .getCorpByIdAsync(selectedCompany?.corporationId as number)
      .then((x) => {
        setCorpProfile(x.data!);
      });
  }, [selectedCompany, userInfo]);

  const renderComponent = () => {
    switch (currentTab) {
      case "profileDetail":
        return <CorpMetadataForm newCorpData={corpProfile?.preference ?? []} />;
      case "branches":
        return <OrgLocations />;
      case "timeOff":
        return <OrgTimeOffReasons />;
      case "Subscription":
        return <OrgSubscription />;
      default:
        return <OrgLocations />;
    }
  };
  return (
    <PageContainer subTitle="Settings to configure your basic details, and notifications.">
      <Row gutter={16}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
        >
          <Card size="default">
            <div style={{ marginTop: "8px", textAlign: "right" }}>
              <Tag color={corpProfile?.isActive ? "blue" : "volcano"}>
                {corpProfile?.isActive
                  ? "Status: Active"
                  : "Status: Terminated"}
              </Tag>
            </div>
            <Row>
              <Col span={24}>
                <Space.Compact
                  block
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    src={
                      <img
                        src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                        alt="avatar"
                      />
                    }
                  />
                </Space.Compact>
                <br></br>
                <Space.Compact
                  block
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Title level={4}>{corpProfile?.name}</Title>
                </Space.Compact>
                <Space.Compact
                  block
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {corpProfile?.description}
                </Space.Compact>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
        >
          <Card size="default">
            <Menu
              onClick={handleTabChange}
              mode="horizontal"
              defaultSelectedKeys={[defaultSelectedKey]}
              selectedKeys={[currentTab]}
              items={items}
              style={{ marginTop: -12 }}
            />
            {renderComponent()}
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default OrgLayout;
