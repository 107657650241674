import { Alert, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { TimeOffOperation } from "../../../serviceoperation/TimeoffOperation";
import { TimeOffReason } from "./timeOff-types";

interface Values {
  reason: string;
  note: string;
}

interface TimeOffModalProps {
  id: number;
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const TimeOffModal: React.FC<TimeOffModalProps> = ({
  id,
  open,
  onCreate,
  onCancel,
}) => {
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const { selectedCompany } = useContext(CompanyContext);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "There was an error processing your request. Please try again later."
  );
  const [timeOffReason, setTimeOffReason] = useState<TimeOffReason[]>([]);

  // Following is set for TimeOff Request Model.
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleTimeOffOk = async (value: Values): Promise<boolean> => {
    let success = false;
    // Process the TimeOff Request...
    const timeoffOperation = new TimeOffOperation();
    await timeoffOperation
      .postScheduleTimeOffAsync(selectedCompany?.corporationId as number, id, {
        timeOffReasionId: value.reason,
        note: value.note ?? "",
      })
      .then((x) => {
        success =
          x.statusCode != undefined && x.statusCode.toString() === "201";

        // This is for the custom Error Message to state you can't process
        // Time off request if there is already an Activity.
        if (x.statusCode != undefined && x.statusCode.toString() === "400") {
          setErrorMessage(x.statusMessage);
        }
      });

    return success;
  };

  useEffect(() => {
    const timeZoneOps = new TimeOffOperation();
    timeZoneOps
      .getTimeOffReasonAsync(selectedCompany?.corporationId as number)
      .then((value) => {
        setTimeOffReason(
          value.map((s) => ({
            key: s.timeOffReasonId,
            name: s.name,
          }))
        );
      });
  }, [selectedCompany]);

  const [form] = Form.useForm();
  return (
    <Modal
      title="Are you sure you want to request time off?"
      open={open}
      onOk={() => {
        form.validateFields().then(async (values) => {
          setConfirmLoading(true);
          if (await handleTimeOffOk(values)) {
            form.resetFields();
            onCreate(values);
          } else {
            setShowError(true);
          }
          setConfirmLoading(false);
        });
      }}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="reason"
          hasFeedback
          label="Reason for Time Off"
          rules={[
            {
              required: true,
              message: "Please select a reason!",
            },
          ]}
          style={{ marginTop: 24 }}
        >
          <Select
            allowClear
            onChange={(e) => console.log(e)}
            style={{ width: "100%", marginTop: "20px" }}
            placeholder="Please select time off reasons"
            options={timeOffReason.map((tor) => {
              return {
                value: tor.key,
                label: tor.name,
              };
            })}
          />
        </Form.Item>

        <Form.Item name="note" label="Notes">
          <Input.TextArea showCount maxLength={255} />
        </Form.Item>
      </Form>
      {showError ? (
        <Alert message={errorMessage} type="error" showIcon />
      ) : null}
    </Modal>
  );
};

export default TimeOffModal;
