import { RedirectRequest } from "@azure/msal-browser";

export const scopes = ["openid", "https://mytimeverseprod.onmicrosoft.com/5c2ca225-d42b-43b9-8a9f-85d421950ecf/Read"];

export const loginRequest : RedirectRequest = {
    scopes: [...scopes]
  };

export const tokenRequest  = {
  scopes: scopes,
};

export const b2cPolicies = {
    names: {
        signIn: "B2C_1_SignUpAndIn",
    },
    authorities: {
      signIn: {
            authority: "https://mytimeverseprod.b2clogin.com/tfp/mytimeverseprod.onmicrosoft.com/B2C_1_SignUpAndIn",
      }
    },
    authorityDomain: "mytimeverseprod.b2clogin.com"
};

const getRedirectUri = () => {
  // Get the current path (including any route parameters)
  const currentPath = window.location.pathname + window.location.search;

  // Construct the full redirect URI by appending the current path to the base URL
  const redirectUri = window.location.origin + currentPath;
  return redirectUri;
};

export const msalConfig = {
    auth: {
      clientId: "123163ac-7643-4742-97b4-476fdc455434",
      authority: b2cPolicies.authorities.signIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: getRedirectUri(),
      //redirectUri: 'https://app.mytimeverse.com/',
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: false,
    },
    cache:{
      //cacheLocation: 'sessionStorage',
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    }
};