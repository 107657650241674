import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, Menu } from "antd";
import { useState } from "react";
import DepartmentRoleView from "./department-role-view";
import MyAvailability from "./my-availability";
import TeamAvailability from "./team-availability";
import TimeOff from "./time-off";
import ScheduleWeek from "./week";

const Schedule = (): JSX.Element => {
  const defaultSelectedKey = "week";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);

  const handleTabChange = (event: any) => {
    setCurrentTab(event.key);
  };

  const renderComponent = () => {
    switch (currentTab) {
      case "week":
        return <ScheduleWeek />;
      case "departmentRoleView":
        return <DepartmentRoleView />;
      case "teamAvailability":
        return <TeamAvailability />;
      case "myAvailability":
        return <MyAvailability />;
      default:
        return <ScheduleWeek />;
    }
  };

  return (
    <PageContainer
      extra={[
        <Button key="3">Operate</Button>,
        <Button key="2">Operate</Button>,
        <Button key="1" type="primary">
          Main Operation
        </Button>,
      ]}
      subTitle="Efficiently organize and oversee your schedule along with handling time off requests!"
      /*footer={[
      <Button key="3">Reset</Button>,
      <Button key="2" type="primary">
        Submit
      </Button>,
    ]}*/
    >
      <ProCard>
        <Menu
          onClick={handleTabChange}
          mode="horizontal"
          defaultSelectedKeys={[defaultSelectedKey]}
          selectedKeys={[currentTab]}
        >
          <Menu.Item key="week">
            <span>Week</span>
          </Menu.Item>
          {/*<Menu.Item key="departmentRoleView">
            <span>Department/Role View</span>
          </Menu.Item>
          <Menu.Item key="teamAvailability">
            <span>Team Availability</span>
          </Menu.Item> */}
          <Menu.Item key="myAvailability">
            <span>My Availability</span>
          </Menu.Item>
        </Menu>
        {renderComponent()}
      </ProCard>
    </PageContainer>
  );
};

export default Schedule;
