import { Button, Modal, Steps, message, notification } from "antd";
import { useCallback, useContext, useState } from "react";
import ProCard from "@ant-design/pro-card";
import ScheduleBulkAdd from "./schedule-bulkadd";
import { PageContainer } from "@ant-design/pro-layout";
import ScheduleBulkAddDetails from "./schedule-bulkadd-details";
import { BulkSchedule, BulkScheduleList } from "./schedule-bulkadd-entity";
import ScheduleBulkAddSummary from "./schedule-bulkadd-summary";
import { EmployeeDetails } from "../../../serviceoperation/entity/EmployeeOperationEntity";
import { ScheduleOperation } from "../../../serviceoperation/ScheduleOperation";
import { CompanyContext } from "../../../contexts/company-context";
import { useNavigate } from "react-router";

const ScheduleBulkAddLayout = (): JSX.Element => {
  const defaultSelectedKey = 0;
  const [currentStep, setCurrentStep] = useState<number>(defaultSelectedKey);
  const [scheduleData, setScheduleData] = useState<BulkScheduleList[]>([]);
  const [employee, SetEmployee] = useState<any>([]);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<string[]>([]);
  const { selectedCompany } = useContext(CompanyContext);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [loadings, setLoadings] = useState<boolean>();
  const navigate = useNavigate();

  const [finalScheduleData, setFinalScheduleData] = useState<BulkSchedule[]>(
    []
  );

  const stepLength = 3;

  const handleStepChange = (value: number) => {
    setCurrentStep(value);
  };

  const saveScheduleData = useCallback(
    (
      data: any,
      employeeData: any,
      employeeDetails: EmployeeDetails[],
      selectedWeek: string[]
    ) => {
      setScheduleData(data);
      SetEmployee(employeeData);
      setEmployeeDetails(employeeDetails);
      setSelectedWeek(selectedWeek);
      setNextDisabled(data.length > 0 ? false : true);
    },
    [currentStep]
  );

  const saveFinalScheduleData = useCallback(
    (data: BulkSchedule[], employeeDetails: any) => {
      setFinalScheduleData(data);
    },
    []
  );

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const submitSchedule = () => {
    setLoadings(true);
    const scheduleOps = new ScheduleOperation();

    console.log("Selected Week", selectedWeek);
    scheduleOps
      .postBulkScheduleAsync(selectedCompany?.corporationId as number, {
        schedules: finalScheduleData.map((x) => ({
          employeeId: x.name,
          occurrences: x.schedule.map((schedule) => ({
            title: schedule.title,
            note: schedule.description,
            workDay: schedule.date,
            startWorkHour: Number(schedule.startHour),
            startWorkMinute: Number(schedule.startMin),
            endWorkHour: Number(schedule.endHour),
            endWorkMinute: Number(schedule.endMin),
            timeZoneId: timeZone,
            branchId: schedule.branchId,
          })),
        })),
        notification: {
          notifyEmployee: true,
          title: "Schedule Posted!",
          body: `${selectedWeek[0]} - ${selectedWeek[6]}`,
        },
      })
      .then((x) => {
        try {
          if (x.statusCode === 201) {
            navigate(`/schedule`);
            message.success("Schedule successfully published!");
            setLoadings(false);
          } else {
            message.error("There was an error trying to process your request!");
            setLoadings(false);
          }
        } catch (error) {
          message.error(
            "There was an error trying to process your request! Please refresh your page and try again!"
          );
          setLoadings(false);
        }
      });
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <ScheduleBulkAdd
            scheduleData={scheduleData}
            onSuccess={saveScheduleData}
            employeeData={employee}
          />
        );
      case 1:
        return (
          <ScheduleBulkAddDetails
            scheduleData={scheduleData}
            onSuccess={saveScheduleData}
            employeeData={employee}
          />
        );
      case 2:
        return (
          <ScheduleBulkAddSummary
            scheduleData={scheduleData}
            employeeData={[]}
            employeeDetails={employeeDetails}
            onSuccess={saveFinalScheduleData}
            selectedWeek={selectedWeek}
          />
        );
    }
  };

  const [openReset, setOpenReset] = useState(false);

  const resetClickHandle = () => {
    setOpenReset(true);
  };

  const handleResetOk = () => {
    setCurrentStep(defaultSelectedKey);
    setScheduleData([]);
    setSelectedWeek([]);
    setOpenReset(false);
  };

  return (
    <PageContainer
      extra={[
        <Button key="2" onClick={() => resetClickHandle()}>
          Reset
        </Button>,
        /*<Button key="1" type="primary">
          Save Draft
        </Button>,*/
      ]}
      title="Schedule Bulk Add"
      subTitle="Streamline the scheduling process for your team by bulk adding their schedules."
      footer={[
        <>
          {currentStep < stepLength - 1 && (
            <Button
              disabled={nextDisabled}
              type="primary"
              onClick={() => next()}
            >
              Next
            </Button>
          )}
          {currentStep === stepLength - 1 && (
            <Button
              type="primary"
              loading={loadings}
              onClick={() => submitSchedule()}
            >
              Submit
            </Button>
          )}
          {currentStep > 0 && <Button onClick={() => prev()}>Previous</Button>}
        </>,
      ]}
    >
      <ProCard>
        <Steps
          type="default"
          size="small"
          current={currentStep}
          onChange={handleStepChange}
          className="site-navigation-steps"
          items={[
            {
              title: "Step 1",
              subTitle: "DateTime selection",
              description: "Add schedule time",
              disabled: true,
            },
            {
              title: "Step 2",
              subTitle: "Misc details",
              description: "Set employees schedule details.",
              disabled: true,
            },
            {
              title: "Step 3",
              subTitle: "Summary",
              description: "Validate and submit the schedule!",
              disabled: true,
            },
          ]}
        />
        {renderComponent()}
      </ProCard>
      <Modal
        title="Reset?"
        open={openReset}
        onOk={handleResetOk}
        onCancel={() => setOpenReset(false)}
      >
        <p>
          Are you certain you wish to initiate a reset for the current form?
        </p>
      </Modal>
    </PageContainer>
  );
};

export default ScheduleBulkAddLayout;
