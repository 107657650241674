import React, { useEffect } from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

interface StripePricingTableProps {
  pricingTableId: string;
  publishableKey: string;
  referenceId: string;
}

// If using TypeScript, add the following snippet to your file as well.
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const StripePricingPage: React.FC<StripePricingTableProps> = ({
  pricingTableId,
  publishableKey,
  referenceId,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ padding: "40px" }}>
      <Title level={3}>You're one step away!</Title>
      <Paragraph>
        Please subscribe to a plan of your choice. You will be required to
        provide payment information even for a free plan. Don't worry—if you
        select our Basic plan, you will never be charged.
      </Paragraph>

      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
        client-reference-id={referenceId}
      ></stripe-pricing-table>
    </div>
  );
};

export default StripePricingPage;
