import React, { useMemo, useState } from "react";
import { Button, Space, Typography, notification, theme } from "antd";
import { NewCorpEntity } from "./corp-new-entity";
import ProCard from "@ant-design/pro-card";
import Context from "@ant-design/icons/lib/components/Context";
import { CorporationOperation } from "../../serviceoperation/CorporationOperation";

const { Title, Paragraph, Text } = Typography;

interface AddCorpSummaryFormProps {
  newCorpData: NewCorpEntity;
  onCreate: (values: NewCorpEntity, response: any) => void;
}

const CorpSummary: React.FC<AddCorpSummaryFormProps> = ({
  newCorpData,
  onCreate,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const corpOps = useMemo(() => new CorporationOperation(), []);

  const processCompanyCreation = async () => {
    setLoading(true);

    /*const metadataArray: Metadaum[] = [];
    // Convert the Metadata Object
    for (const key in newCorpData.metadata) {
      if (newCorpData.metadata.hasOwnProperty(key)) {
        const metadataID: number = parseInt(key, 10);
        const metadataValue: string = newCorpData.metadata[key];
        const metadataItem: Metadaum = {
          metadataID,
          metadataValue,
        };
        metadataArray.push(metadataItem);
      }
    }*/

    // Process Insert Company Update
    // So that we can process the Awesomeness.
    corpOps
      .postCorp({
        name: newCorpData.name!,
        description: newCorpData.description,
        address: {
          addressLine1: newCorpData.address!.addressLine1!,
          addressLine2: newCorpData.address?.addressLine2,
          addressLine3: undefined,
          city: newCorpData.address!.city!,
          state: newCorpData.address!.state!,
          zip: newCorpData.address!.zip!,
          countryAlpha2Code: "US",
        },
        timeZoneId: newCorpData.timeZoneId!,
        contact: {
          firstName: newCorpData.contact!.firstName,
          lastName: newCorpData.contact!.lastName,
          email: newCorpData.contact!.email,
        },
        metadata: Object.keys(newCorpData.metadata!).map((key) => {
          const metadataID: number = parseInt(key, 10);
          const metadataValue: string = newCorpData.metadata![key];
          return {
            metadataID,
            metadataValue,
          };
        }),
        branch: newCorpData!.branch!.map((x) => ({
          name: x.name,
          branchCode: "str",
          description: x.description,
          address: {
            addressLine1: x.addressLine1!,
            addressLine2: x.addressLine2,
            addressLine3: undefined,
            city: x.city!,
            state: x.state!,
            zip: x.zip!,
            countryAlpha2Code: "US",
          },
          timeZoneId: x.timeZoneId!,
          department: x.departments?.map((dep) => ({
            name: dep.name,
            description: dep.description,
          })),
        })),
        timOffReasons: [
          {
            name: "Personal Time Off",
            description: "Personal time off.",
          },
          {
            name: "Bereavement",
            description:
              "Bereavement leave is paid leave which is available to an employee at the time of death or funeral of a member of the employee's immediate family.",
          },
          {
            name: "Other",
            description: "Other reason that can't be categorized.",
          },
        ],
      })
      .then((x) => {
        //console.log(x);
        if (x.statusCode === 201) {
          api.success({
            message: `Thank you!`,
            description: (
              <Context.Consumer>
                {() =>
                  `We've successfully processed your request, please stay on this page while we redirect you!`
                }
              </Context.Consumer>
            ),
          });
          // Introduce a 5-second delay before calling onCreate
          // Such a Placebo effect :P
          setTimeout(() => {
            onCreate(newCorpData, x);
          }, 5000); // 5000 milliseconds = 5 seconds
        } else {
          api.error({
            message: `Oops!`,
            description: (
              <Context.Consumer>
                {() =>
                  `We're sorry for the inconvenience, but we're currently facing some issues processing your request. Please try again later or feel free to contact us for assistance.`
                }
              </Context.Consumer>
            ),
          });
          setLoading(false);
        }
      });
  };

  return (
    <div style={{ padding: "40px" }}>
      <Title level={3}>You're almost done!</Title>
      <Paragraph>
        Let's pause for a moment to carefully review your inputs. Once you're
        satisfied that everything is in order, just a click away lies the power
        to create your organization.
      </Paragraph>
      <ProCard style={{ backgroundColor: token.colorBgContainerDisabled }}>
        <Title level={5}>Corporation Info</Title>
        <Space size="large">
          <Paragraph>Company Name:</Paragraph>{" "}
          <Paragraph>{newCorpData.name}</Paragraph>
        </Space>
        <Title level={5}>Branches</Title>
        {newCorpData.branch
          ? newCorpData.branch.map((x, index) => {
              return (
                <div key={x.name}>
                  {" "}
                  <Space size="large">
                    <Paragraph>Name:</Paragraph> <Paragraph>{x.name}</Paragraph>
                    <br />
                  </Space>
                </div>
              );
            })
          : null}
      </ProCard>
      {contextHolder}
      <Button
        loading={loading}
        type="primary"
        style={{ marginTop: "24px" }}
        onClick={processCompanyCreation}
      >
        Looks Good!
      </Button>
    </div>
  );
};

export default CorpSummary;
