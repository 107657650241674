import React from "react";
import { CorpDetailAPIEntity } from "../serviceoperation/entity/CorporationOperationEntity";

export interface ICompanyContext {
  companyData: Array<CorpDetailAPIEntity>;
  selectedCompany?: CorpDetailAPIEntity;
  updateSelectedCompany: (companyId: number) => void;
}

export const CompanyContext = React.createContext<ICompanyContext>({
  companyData: [],
  updateSelectedCompany: () => {
    return;
  },
});
