import { constants } from "../constants";
import { TimeZone } from "./entity/TimeZoneOperationEntity";

export default interface ITimeZoneOperation {
  getTimeZoneAsync(): Promise<TimeZone[]>;
}

export class TimeZoneOperation implements ITimeZoneOperation {
  async getTimeZoneAsync(): Promise<TimeZone[]> {
    try {
      const response = await fetch(
        `${constants.BASE_URL}${constants.GET_TIMEZONE_ENDPOINT}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: ``,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
