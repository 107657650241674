import { SwapOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Empty, List, Row, Skeleton, message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ScheduleReplacementOperation } from "../../serviceoperation/ScheduleReplacementOperation";
import { CompanyContext } from "../../contexts/company-context";
import { GetScheduleReplacement } from "../../serviceoperation/entity/ScheduleReplacementOperationEntity";
import {
  AmPMFormat,
  padTo2Digits,
  ConvertTo12hrStr,
} from "../../Util/dateutil";
import { getInitials } from "../../Util/stringutil";

interface NotificationProps {
  manager: boolean;
  empl: boolean;
}
const Notification: React.FC<NotificationProps> = ({ manager, empl }) => {
  const [loading, setLoading] = useState(true);
  const [replacement, setReplacement] = useState<GetScheduleReplacement[]>([]);
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {
    const scheduleReplacementOps = new ScheduleReplacementOperation();
    const fetchData = async () => {
      try {
        await scheduleReplacementOps
          .getScheduleReplacementAsync(
            selectedCompany?.corporationId as number,
            1,
            100
          )
          .then((value) => {
            setReplacement(value.data?.results || []);
          });
      } catch (error) {
        // Handle error
        /*setMessage(
          "An error occurred while attempting to retrieve potential matches. Kindly attempt the operation again later."
        );
        setShowError(true);*/
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCompany]);

  const handleApprove = (itemId: string) => {
    const scheduleReplacementOps = new ScheduleReplacementOperation();

    scheduleReplacementOps
      .postScheduleReplacementStatusAsync(
        selectedCompany?.corporationId as number,
        itemId,
        true
      )
      .then((value) => {
        if (value.statusCode === 201) {
          // Handle denial logic for the item with itemId
          message.success(`Successfully approved the request.`);

          // Remove the denied item from the list
          // We don't need to refresh the network call since we're just rejecting.
          setReplacement((prevData) =>
            prevData.filter((item) => item.replacementId !== itemId)
          );
        } else {
          message.error(
            `We encountered an issue while processing your request. Please try again later. Apologies for any inconvenience.`
          );
        }
      });
  };

  const handleDeny = (itemId: string) => {
    const scheduleReplacementOps = new ScheduleReplacementOperation();

    scheduleReplacementOps
      .postScheduleReplacementStatusAsync(
        selectedCompany?.corporationId as number,
        itemId,
        false
      )
      .then((value) => {
        if (value.statusCode === 201) {
          // Handle denial logic for the item with itemId
          message.warning(`Successfully denied the request.`);

          // Remove the denied item from the list
          // We don't need to refresh the network call since we're just rejecting.
          setReplacement((prevData) =>
            prevData.filter((item) => item.replacementId !== itemId)
          );
        } else {
          message.error(
            `We encountered an issue while processing your request. Please try again later. Apologies for any inconvenience.`
          );
        }
      });
  };

  return (
    <div>
      <h2>Do you want to accept the swap?</h2>
      {loading ? (
        <Skeleton active />
      ) : replacement.length > 0 ? (
        <List
          itemLayout="vertical"
          dataSource={replacement}
          renderItem={(item) => (
            <List.Item
              style={{ display: "flex", flexDirection: "column" }}
              actions={[
                <Button
                  type="primary"
                  onClick={() => handleApprove(item.replacementId)}
                >
                  Approve
                </Button>,
                <Button onClick={() => handleDeny(item.replacementId)}>
                  Deny
                </Button>,
              ]}
            >
              {/* Part 1: Avatar with Name */}
              <div style={{ marginBottom: 8 }}>
                <Avatar>
                  {getInitials(item.replacementSchedule.employeeName)}
                </Avatar>
                <span style={{ marginLeft: 8 }}>
                  {item.replacementSchedule.employeeName}
                </span>
              </div>

              {/* Part 2: Date, Day, Month, Title, and Description */}
              <Row gutter={16}>
                <Col span={2}>
                  <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                    {moment(new Date(item.replacementSchedule.workDate)).format(
                      "DD"
                    )}
                  </div>
                </Col>
                <Col span={2}>
                  <div
                    style={{
                      marginBottom: -4,
                    }}
                  >
                    {moment(new Date(item.replacementSchedule.workDate)).format(
                      "ddd"
                    )}
                  </div>
                  <div>
                    {moment(new Date(item.replacementSchedule.workDate)).format(
                      "MMM"
                    )}
                  </div>
                </Col>
                <Col span={1}>
                  <div style={{ fontSize: "24px" }}>|</div>
                </Col>
                <Col span={18}>
                  {/* Continue with the rest of the Part 2 styling */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: -4,
                    }}
                  >
                    {`${ConvertTo12hrStr(
                      item.replacementSchedule.startWorkHour
                    )}:${padTo2Digits(
                      item.replacementSchedule.startWorkMinute
                    )} ${AmPMFormat(
                      item.replacementSchedule.startWorkHour
                    )} - ${ConvertTo12hrStr(
                      item.replacementSchedule.endWorkHour
                    )}:${padTo2Digits(
                      item.replacementSchedule.endWorkMinute
                    )} ${AmPMFormat(item.replacementSchedule.endWorkHour)}`}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {item.replacementSchedule.employeeTitle} ,{" "}
                    {item.replacementSchedule.branch}
                  </div>
                </Col>
              </Row>

              <div style={{ textAlign: "center", marginTop: "24px" }}>
                <SwapOutlined style={{ fontSize: "24px" }} />
              </div>

              {/* This section is to Schedule */}
              {/* Part 1: Avatar with Name */}
              <div style={{ marginBottom: 8 }}>
                <Avatar>{getInitials(item.schedule.employeeName)}</Avatar>
                <span style={{ marginLeft: 8 }}>
                  {item.schedule.employeeName}
                </span>
              </div>

              {/* Part 2: Date, Day, Month, Title, and Description */}
              <Row gutter={16}>
                <Col span={2}>
                  <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                    {moment(new Date(item.schedule.workDate)).format("DD")}
                  </div>
                </Col>
                <Col span={2}>
                  <div
                    style={{
                      marginBottom: -4,
                    }}
                  >
                    {moment(new Date(item.schedule.workDate)).format("ddd")}
                  </div>
                  <div>
                    {moment(new Date(item.schedule.workDate)).format("MMM")}
                  </div>
                </Col>
                <Col span={1}>
                  <div style={{ fontSize: "24px" }}>|</div>
                </Col>
                <Col span={18}>
                  {/* Continue with the rest of the Part 2 styling */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: -4,
                    }}
                  >
                    {`${ConvertTo12hrStr(
                      item.schedule.startWorkHour
                    )}:${padTo2Digits(
                      item.schedule.startWorkMinute
                    )} ${AmPMFormat(
                      item.schedule.startWorkHour
                    )} - ${ConvertTo12hrStr(
                      item.schedule.endWorkHour
                    )}:${padTo2Digits(
                      item.schedule.endWorkMinute
                    )} ${AmPMFormat(item.schedule.endWorkHour)}`}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {item.schedule.employeeTitle} , {item.schedule.branch}
                  </div>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      ) : (
        <Empty
          imageStyle={{ height: 60 }}
          description={<span>All caught up on notification!</span>}
        />
      )}
    </div>
  );
};

export default Notification;
