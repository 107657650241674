import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const NotFound = (): JSX.Element => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="The page you have visited does not exist."
      extra={
        <Link to="/home">
          <Button type="primary">Home</Button>
        </Link>
      }
    />
  );
};

export default NotFound;
