import {
  Form,
  Modal,
  Input,
  Radio,
  DatePicker,
  TimePicker,
  Select,
  Alert,
} from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { EmployeeOperation } from "../../../serviceoperation/EmployeeOperation";
import { TimeZoneOperation } from "../../../serviceoperation/TimezoneOperation";
import { EmployeeType } from "../week-types";
import { TimeZoneType } from "./timeZone-types";
import dayjs, { Dayjs } from "dayjs";
import { ScheduleOperation } from "../../../serviceoperation/ScheduleOperation";
import { BranchOperation } from "../../../serviceoperation/BranchOperation";
import { LocationType } from "./location-types";
import { TimeOffOperation } from "../../../serviceoperation/TimeoffOperation";

interface Values {
  employee: string;
  title: string;
  scheduleDate: Dayjs;
  scheduleTime: Dayjs[];
  note: string;
  modifier: string;
  timeZone: string;
  location: string;
}

interface AddScheduleFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

//AddScheduleForm
const AddScheduleForm: React.FC<AddScheduleFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const rangeConfig = {
    rules: [
      {
        type: "array" as const,
        required: true,
        message: "Please select time!",
      },
    ],
  };
  const { selectedCompany } = useContext(CompanyContext);
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const format = "HH:mm A";
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showWarning, setWarning] = useState(false);
  // Get TimeZone...
  const [timeZones, setTimeZone] = useState<TimeZoneType[]>([]);
  // List of employee for a given logged in user.
  // This will contain a data regardless of who is logged in
  // as a logged in user will be in this list.
  const [employee, setEmployee] = useState<EmployeeType[]>([]);
  // Get Corporation Locations...
  const [location, setLocation] = useState<LocationType[]>([]);
  const timeZoneOps = new TimeZoneOperation();
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const [userTimeOffError, setUserTimeOffError] = useState<boolean>(false);

  const [selectedEmployee, setSelectedEmployee] = useState<React.Key>();
  const [selectedDateTime, setSelectedDateTime] = useState<Date>();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  useEffect(() => {
    const empId = userInfo?.employeeCorporation
      ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
      .map((x) => x.employeeId)
      .at(0);
    const fetchEmployees = async () => {
      const employeeOps = new EmployeeOperation();
      const response = await employeeOps.getEmployeeAsync(
        selectedCompany?.corporationId as number,
        1,
        255
      );
      setEmployee(
        response.results
          .filter((x) => x.employeeId !== empId && x.isActive)
          .map((s) => ({
            key: s.employeeId,
            name: `${s.firstName} ${s.lastName}`,
          }))
      );
    };

    fetchEmployees();
  }, [selectedCompany]);

  useMemo(() => {
    timeZoneOps.getTimeZoneAsync().then((value) => {
      setTimeZone(
        value.map((s) => ({
          key: s.id,
          name: s.id,
        }))
      );
    });
  }, []);

  const insertSchedule = async (values: Values): Promise<boolean> => {
    const scheduleOps = new ScheduleOperation();
    let success = false;
    const scheduleDate = new Date(values.scheduleDate.toDate());
    scheduleDate.setHours(0, 0, 0, 0);
    await scheduleOps
      .postScheduleAsync(
        selectedCompany?.corporationId as number,
        values.employee,
        {
          title: values.title,
          note: values.note,
          workDay: scheduleDate,
          startWorkHour: dayjs(values.scheduleTime[0]).hour(),
          startWorkMinute: dayjs(values.scheduleTime[0]).minute(),
          endWorkHour: dayjs(values.scheduleTime[1]).hour(),
          endWorkMinute: dayjs(values.scheduleTime[1]).minute(),
          timeZoneId: values.timeZone,
          branchId: values.location,
        }
      )
      .then((x) => {
        success =
          x.statusCode != undefined && x.statusCode.toString() === "201";
      });

    return success;
  };

  useEffect(() => {
    const branchOps = new BranchOperation();
    branchOps
      .getBranchAsync(selectedCompany?.corporationId as number)
      .then((value) => {
        if (value.data) {
          setLocation(
            value.data.map((s) => ({
              key: s.branchID,
              name: s.name,
            }))
          );
        }
      });
  }, []);

  // On Change of Department selection.
  const handleSelectedDateChange = (selectedDate: any) => {
    setSelectedDateTime(selectedDate);
  };

  // UserEffect call to Make sure Employee is not set to Off on this day.
  useEffect(() => {
    if (selectedEmployee && selectedDateTime) {
      setUserTimeOffError(false);
      setShowError(false);
      setWarning(false);

      const timeOffOps = new TimeOffOperation();
      timeOffOps
        .getScheduleTimeOffAsync(selectedCompany?.corporationId as number, {
          includeDirectReport: true,
          weekOf: new Date().toDateString(),
          employeeId: selectedEmployee as string,
          page: 1,
          pageSize: 25,
        })
        .then((value) => {
          const currDate = new Date();
          currDate.setHours(0, 0, 0, 0);
          const newVal = value.results.filter(
            (x) =>
              x.employee.employeeId == selectedEmployee &&
              x.timeOffStatus != "Cancelled" &&
              new Date(x.startDate) <= selectedDateTime &&
              new Date(x.endDate) >= selectedDateTime
          );
          if (newVal.length > 0) {
            setUserTimeOffError(true);
            setShowError(true);
          }
        });

      const empOps = new EmployeeOperation();
      empOps
        .getEmployeeAvailabilityAsync(
          selectedCompany?.corporationId as number,
          selectedEmployee as string
        )
        .then((availability) => {
          setWarning(
            availability.data?.some(
              (x) =>
                x.dayIndex === new Date(selectedDateTime).getDay() &&
                !x.available
            ) ?? false
          );
        });
    }
  }, [selectedEmployee, selectedDateTime]);

  return (
    <Modal
      open={open}
      title="Add new Schedule"
      okText="Create"
      cancelText="Cancel"
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      onOk={() => {
        // Before we test the form, we need to
        // make sure selected Employee does not have
        // TimeOff Request.
        if (!userTimeOffError) {
          setConfirmLoading(true);
          form
            // Validate the required fields.
            .validateFields()
            .then(async (values) => {
              // Insert Employee Schedule..
              // This is where you insert Schedule
              // and then return to Parent Component. :)
              if (await insertSchedule(values)) {
                form.resetFields();
                onCreate(values);
              } else {
                setShowError(true);
                // Stay on the Page and keep the Modal open and display Error message.
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
          setConfirmLoading(false);
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="employee"
          hasFeedback
          label="Employee"
          rules={[
            {
              required: true,
              message: "Please select an employee!",
            },
          ]}
          style={{ marginTop: 24 }}
        >
          <Select
            showSearch
            onSelect={setSelectedEmployee}
            placeholder="Select Employee"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={employee.map((emp) => {
              return {
                value: emp.key,
                label: emp.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input the title of schedule!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Schedule Date Time" style={{ marginBottom: 12 }}>
          <Form.Item
            name="scheduleDate"
            rules={[
              {
                required: true,
                message: "Please select date",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(33% - 8px)",
              margin: "0 4px",
            }}
          >
            <DatePicker
              onSelect={handleSelectedDateChange}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            name="scheduleTime"
            {...rangeConfig}
            style={{
              display: "inline-block",
              //width: "calc(33% - 8px)",
              margin: "0 4px",
            }}
          >
            <TimePicker.RangePicker format={format} />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="timeZone"
          rules={[
            {
              required: true,
              message: "Please select a timezone!",
            },
          ]}
          initialValue={timeZone}
        >
          <Select
            placeholder="Select TimeZone"
            defaultValue={timeZone}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={timeZones.map((tmz) => {
              return {
                value: tmz.key,
                label: tmz.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="location" label="Location">
          <Select
            placeholder="Select Location"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={location.map((loc) => {
              return {
                value: loc.key,
                label: loc.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="note" label="Notes">
          <Input.TextArea showCount maxLength={255} />
        </Form.Item>
        {/*<Form.Item
          name="modifier"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group>
            <Radio value="public">Public</Radio>
            <Radio value="private">Private</Radio>
          </Radio.Group>
          </Form.Item>*/}
      </Form>
      {showError ? (
        <Alert
          message={
            !userTimeOffError
              ? "There was an error processing your request. Please try again later."
              : "The selected employee has submitted a time-off request for this period."
          }
          type="error"
          showIcon
        />
      ) : null}
      {showWarning ? (
        <Alert
          message={
            "The chosen employee's preference does not align with the schedule's specifications"
          }
          type="warning"
          showIcon
        />
      ) : null}
    </Modal>
  );
};

export default AddScheduleForm;
