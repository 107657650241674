import { Result } from "antd";

const Unauthorized = (): JSX.Element => {
  return (
    <Result
      status="403"
      title="401"
      subTitle="Your account is currently suspended, please contact a system administrator to resolve the issue."
    />
  );
};

export default Unauthorized;
