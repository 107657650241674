import { Form, Modal, Input, Select, Alert } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { EmployeeOperation } from "../../../serviceoperation/EmployeeOperation";
import { BranchOperation } from "../../../serviceoperation/BranchOperation";
import { EmployeeType } from "../../schedule/week-types";
import { LocationType } from "../../schedule/modal/location-types";
import { DepartmentOperation } from "../../../serviceoperation/DepartmentOperation";
import { Departmenttypes } from "./department-types";

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  manager: string;
  title: string;
  location: string;
  department: number;
}

interface AddEmployeeFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

//AddScheduleForm
const AddEmployeeForm: React.FC<AddEmployeeFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { selectedCompany } = useContext(CompanyContext);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // List of employee for a given logged in user.
  // This will contain a data regardless of who is logged in
  // as a logged in user will be in this list.
  const [employee, setEmployee] = useState<EmployeeType[]>([]);
  // Get Corporation Locations...
  const [location, setLocation] = useState<LocationType[]>([]);
  const [department, setDepartment] = useState<Departmenttypes[]>([]);

  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const [showDepart, setDepart] = useState(false);

  useEffect(() => {
    const empId = userInfo?.employeeCorporation
      ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
      .map((x) => x.employeeId)
      .at(0);
    const fetchEmployees = async () => {
      const employeeOps = new EmployeeOperation();
      const response = await employeeOps.getEmployeeAsync(
        selectedCompany?.corporationId as number,
        1,
        255
      );
      setEmployee(
        response.results
          .filter((x) => x.isActive)
          .map((s) => ({
            key: s.employeeId,
            name: `${s.firstName} ${s.lastName}`,
          }))
      );
    };

    fetchEmployees();
  }, [selectedCompany]);

  const inviteEmployee = async (values: Values): Promise<boolean> => {
    setConfirmLoading(true);
    let success = false;

    const empOps = new EmployeeOperation();
    await empOps
      .inviteEmployeeAsync({
        recipient: {
          name: `${values.firstName}${values.lastName}`,
          email: values.email,
          displayName: `${values.firstName} ${values.lastName}`,
          givenName: values.firstName,
          surname: values.lastName,
          title: values.title,
          ManagerId: values.manager,
          EmployeeAttribute: {
            departmentId: values.department,
            jobId: 1,
            salary: 1,
            employeeType: 1,
            startDate: new Date(),
          },
        },
        sender: {
          name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          EmployeeId:
            userInfo?.employeeCorporation
              ?.filter(
                (x) => x.corporationId === selectedCompany?.corporationId
              )
              .map((x) => x.employeeId)
              .at(0) || "",
        },
        CompanyInformation: {
          Name: selectedCompany?.name || "",
          CompanyId: selectedCompany?.corporationId as number,
        },
      })
      .then((x) => {
        success =
          x.statusCode != undefined && x.statusCode.toString() === "200";

        // Set Error Message for Conflict.
        if (x.statusCode != undefined && x.statusCode.toString() === "409") {
          setShowError(true);
          setErrorMessage("User with given email already exist");
        }
      });
    setConfirmLoading(false);
    return success;
  };

  useEffect(() => {
    const branchOps = new BranchOperation();
    branchOps
      .getBranchAsync(selectedCompany?.corporationId as number)
      .then((value) => {
        if (value.data) {
          setLocation(
            value.data.map((s) => ({
              key: s.branchID,
              name: s.name,
            }))
          );
        }
      });
  }, [selectedCompany]);

  // On Change of Department selection.
  const handleLocationChange = (changedValues: React.Key) => {
    const departmentOps = new DepartmentOperation();
    departmentOps
      .getDepartmentByAsync(
        selectedCompany?.corporationId as number,
        changedValues as number
      )
      .then((value) => {
        setDepartment(
          value.data.map((s) => ({
            key: s.departmentId,
            name: s.name,
          }))
        );
      });
    // if changedValues is not null or undefined
    // Then Enable the Department fields.
    setDepart(true);
  };
  return (
    <Modal
      open={open}
      title="Invite New Employee"
      okText="Create"
      cancelText="Cancel"
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      onOk={() => {
        setConfirmLoading(true);
        form
          .validateFields()
          .then(async (values) => {
            // Insert / Invite Employee
            // to join the company.
            if (await inviteEmployee(values)) {
              form.resetFields();
              onCreate(values);
            } else {
              setShowError(true);
              // Stay on the Page and keep the Modal open and display Error message.
            }
          })
          .catch((info) => {
            setShowError(true);
            setConfirmLoading(false);
          });
        setConfirmLoading(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          label="Employee Information"
          style={{ marginTop: 24, marginBottom: 12 }}
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please enter first name",
              },
            ]}
            style={{
              display: "inline-block",
              // width: "calc(33% - 8px)",
              margin: "0 4px",
            }}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please enter last name",
              },
            ]}
            style={{
              display: "inline-block",
              //width: "calc(33% - 8px)",
              margin: "0 4px",
            }}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter employee's email.",
              },
            ]}
            style={{
              //display: "inline-block",
              //width: "calc(33% - 8px)",
              margin: "0 4px",
              marginTop: "8px",
            }}
          >
            <Input placeholder="Employee's Email Address" />
          </Form.Item>
          <Form.Item
            name="title"
            style={{
              //display: "inline-block",
              //width: "calc(33% - 8px)",
              margin: "0 4px",
              marginTop: "8px",
            }}
            rules={[
              {
                required: true,
                message: "Please enter employee's title!",
              },
            ]}
          >
            <Input placeholder="Employee's Title" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="manager"
          hasFeedback
          label="Manager"
          rules={[
            {
              required: true,
              message: "Please select an manager!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Manager"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={employee.map((emp) => {
              return {
                value: emp.key,
                label: emp.name,
              };
            })}
          />
        </Form.Item>

        <Form.Item label="Department">
          <Form.Item name="location">
            <Select
              onSelect={handleLocationChange}
              placeholder="Select Employee Branch"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={location.map((loc) => {
                return {
                  value: loc.key,
                  label: loc.name,
                };
              })}
            />
          </Form.Item>
          <Form.Item name="department">
            <Select
              placeholder="Select Employee Department"
              allowClear
              showSearch
              disabled={!showDepart}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={department.map((loc) => {
                return {
                  value: loc.key,
                  label: loc.name,
                };
              })}
            />
          </Form.Item>
        </Form.Item>
      </Form>
      {showError ? (
        <Alert
          message={
            errorMessage && errorMessage !== ""
              ? errorMessage
              : "There was an error processing your request. Please try again later."
          }
          type="error"
          showIcon
        />
      ) : null}
    </Modal>
  );
};

export default AddEmployeeForm;
