import React from "react";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth-config";
import { BrowserRouter } from "react-router-dom";
import ReactDOMClient from "react-dom/client";
import { DarkModeProvider } from "./contexts/theme-context-provider";

const pca = new PublicClientApplication(msalConfig);
const container = document.getElementById("root");
if (!container) throw new Error("Root container missing in index.html");
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <DarkModeProvider>
          <App />
        </DarkModeProvider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
