import { Button, Result } from "antd";

interface InternalServerProps {
  displayHome: boolean;
}

const InternalServer = (props: InternalServerProps): JSX.Element => {
  const { displayHome } = props;
  const toHome = displayHome ? (
    <a href="/home">
      <Button type="primary">Home</Button>
    </a>
  ) : undefined;

  return (
    <Result
      status="500"
      title="500"
      subTitle="An unknown error ocurred."
      extra={toHome}
    />
  );
};

export default InternalServer;
