import { Form, theme, Input, Button, Typography } from "antd";
import { useContext, useState } from "react";
import { CompanyContext } from "../../../contexts/company-context";
import { AddBulkScheduleFormProps } from "./schedule-bulkadd-entity";
import { ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons";

const ScheduleBulkAddDetails: React.FC<AddBulkScheduleFormProps> = ({
  scheduleData,
  employeeData,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { selectedCompany } = useContext(CompanyContext);
  const { useToken } = theme;
  const { token } = useToken();
  const { Title, Paragraph } = Typography;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [expandedItem, setExpandedItem] = useState(null);

  const onFinish = (values: Record<string, any>) => {
    console.log("Form values:", values);
  };

  const toggleExpand = (entryKey: any) => {
    if (expandedItem === entryKey) {
      setExpandedItem(null); // Close the currently expanded item
    } else {
      setExpandedItem(entryKey); // Expand the clicked item
    }
  };

  const isItemExpanded = (entryKey: any) => {
    return expandedItem === entryKey;
  };

  return (
    <>
      <Paragraph style={{ marginTop: "12px" }}>
        Please utilize this section to add additional information. In the event
        that any fields are left empty, default values will be automatically
        applied. Simply click the expand icon next to each employee to complete
        their schedule title and provide a description of the tasks they will be
        undertaking.
      </Paragraph>

      <Form
        name="entryForm"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
        size="small"
      >
        {scheduleData.map((entry) => (
          <div key={entry.key}>
            <Title level={4}>
              {!isItemExpanded(entry.key) ? (
                <ExpandAltOutlined onClick={() => toggleExpand(entry.key)} />
              ) : (
                <ShrinkOutlined onClick={() => toggleExpand(entry.key)} />
              )}{" "}
              {"  "}
              {employeeData
                .filter((x) => x.value === entry.name)
                .map((map) => map.label)
                .at(0)}
            </Title>
            {/*<Button type="link" onClick={() => toggleExpand(entry.key)}>
              {isItemExpanded(entry.key) ? "Collapse" : "Expand"}
              </Button>*/}

            <Paragraph>
              Hi there, how are you doing today! <br /> Hi there
              <br />
              Scheduled Hours: 40 hours
            </Paragraph>
            {isItemExpanded(entry.key) && (
              <>
                <Form.Item
                  label="Title"
                  name={`title-${entry.key}`}
                  initialValue={entry.name}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Timezone">
                  <Input disabled={true} value={timeZone} />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name={`description-${entry.key}`}
                  initialValue=""
                >
                  <Input.TextArea />
                </Form.Item>
              </>
            )}
          </div>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ScheduleBulkAddDetails;
