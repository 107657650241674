import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  message,
  Row,
  Space,
  Table,
  Tag,
  theme,
  Typography,
  Modal,
} from "antd";
import {
  CloudDownloadOutlined,
  DeleteFilled,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { OrgEmployeeType } from "./team-types";
import { ColumnsType } from "antd/es/table";
import { PageContainer } from "@ant-design/pro-layout";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { CompanyContext } from "../../contexts/company-context";
import AddEmployeeForm from "./modal/addEmployee";
import { EmployeeDetails } from "../../serviceoperation/entity/EmployeeOperationEntity";
import "./team.css";
import ProfileLayout from "./profileLayout";
import { Link } from "react-router-dom";
import { AuthenticationContext } from "../../contexts/authentication-context";

const Team = () => {
  interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
  }

  const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <div style={{ color: token.colorTextDescription }}>
      <Paragraph
        style={{ color: token.colorTextBase }}
        className="site-description-item-profile-p-label"
      >
        {title}:
      </Paragraph>
      {content}
    </div>
  );
  const { useToken } = theme;
  const { token } = useToken();
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { Paragraph } = Typography;
  const [windowWidth, setWindowWidth] = useState(0);
  const isSmallScreen = windowWidth <= 576;
  const { selectedCompany } = useContext(CompanyContext);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [deFaultPageSize] = useState<number>(25);
  const [updateEmpList, SetupdateEmpList] = useState(false);
  const [reSendInviteLoading, setreSendInviteLoading] =
    useState<boolean>(false);

  const [OpenAddUser, setOpenAddUser] = useState(false);

  // List of employee for a given logged in user.
  // This will contain a data regardless of who is logged in
  // as a logged in user will be in this list.
  const [employee, setEmployee] = useState<OrgEmployeeType[]>([]);
  const [selectedEmp, setselectedEmp] = useState<EmployeeDetails>();
  const employeeOps = useMemo(() => new EmployeeOperation(), []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchEmployeeData = async (
    corpId: number,
    page: number,
    pageSize: number
  ) => {
    setDataLoading(true);
    const response = await employeeOps.getEmployeeAsync(
      corpId,
      page,
      pageSize,
      true
    );
    setDataLoading(false);
    return response;
  };

  const fetchListofEmployee = async (
    corpId: number,
    page: number,
    pageSize: number
  ) => {
    const response = await fetchEmployeeData(corpId, page, pageSize);
    setTotalResult(response.rowCount);
    return response.results.map((s) => ({
      key: s.employeeId,
      name: `${s.firstName} ${s.lastName}`,
      email: s.email,
      title: s.title,
      status:
        s.invitePending && s.isActive
          ? "Pending Invitation"
          : s.isActive
          ? "Active"
          : "Terminated",
    }));
  };
  // Using this effect call to fetch the Employee List
  useEffect(() => {
    fetchListofEmployee(
      selectedCompany?.corporationId as number,
      1,
      deFaultPageSize
    ).then(setEmployee);
  }, [employeeOps, selectedCompany, updateEmpList]);

  const columns: ColumnsType<OrgEmployeeType> = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      render(text, record) {
        return (
          <Space>
            <Avatar
              src={
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg"
                  alt="avatar"
                />
              }
            />
            <div>
              {text}
              <br />
              {record.title}
            </div>
          </Space>
        );
      },
      filters: employee.map((s) => ({
        text: s.name,
        value: s.name,
      })),
      filterSearch: true,
      onFilter: (value, record) => record.name === value,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            color={
              status === "Active"
                ? "green"
                : status === "Pending Invitation"
                ? "blue"
                : "volcano"
            }
          >
            {status}{" "}
          </Tag>
        </>
      ),
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Terminated",
          value: "Terminated",
        },
        {
          text: "Pending Invitation",
          value: "Pending Invitation",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.status === value,
      responsive: ["xs", "sm"],
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "30%",
      onFilter: (value, record) => record.status === value,
      responsive: ["sm"],
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <Space size="middle">
            {record.status !== "Requested" ? (
              <>
                {record.status !== "Terminated" &&
                userInfo?.employeeCorporation?.find(
                  (y) => y.corporationId === selectedCompany!.corporationId
                )?.employeeId !== record.key ? (
                  <DeleteFilled
                    style={{ color: "red" }}
                    onClick={() => {
                      deleteEmployee(record.key.toString(), record.name);
                    }}
                  />
                ) : null}
                {/*<EditOutlined
                  onClick={() => {
                    showDrawer(record.key);
                  }}
                />*/}
                <InfoCircleOutlined
                  onClick={() => {
                    showDrawer(record.key);
                  }}
                />
              </>
            ) : null}
          </Space>
        </>
      ),
      responsive: ["sm"],
      //width: isSmallScreen ? "50%" : "20%",
    },
  ];

  const { confirm } = Modal;
  const deleteEmployee = (empId: string, empName: string) => {
    confirm({
      title: `Are you sure you want to terminate ${empName}?`,
      async onOk() {
        try {
          const result = await employeeOps.deleteEmployeeAsync(
            selectedCompany?.corporationId as number,
            empId
          );
          if (result) {
            message.success("Successfully terminated employee.");
            return;
          } else {
            message.error("An error occured while processing your request.");
          }
        } catch (e) {
          message.error("An error occured while processing your request.");
        }
      },
      onCancel() {
        return;
      },
    });

    /*const empOps = async () => {
      const result = await employeeOps.deleteEmployeeAsync(1, empId);
      if (result) {
        message.success("Successfully terminated employee.");
      } else {
        message.error("An error occured while processing your request.");
      }
    };
    empOps();*/
  };

  const reInviteEmployee = async (empId: React.Key) => {
    setreSendInviteLoading(true);
    const empOps = new EmployeeOperation();
    await empOps
      .reInviteEmployeeAsync(
        selectedCompany?.corporationId as number,
        empId as string
      )
      .then((x) => {
        if (x.statusCode != undefined && x.statusCode === 200) {
          message.success("Successfully re-sent the invitation.");
        } else {
          message.success(
            "An error occured while trying to sent the invitation. Please try again!"
          );
        }
      });
    setreSendInviteLoading(false);
  };

  const onUserCreate = () => {
    //console.log("Received values of form: ", values);
    setOpenAddUser(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    SetupdateEmpList(!updateEmpList);

    //Show Alert successfully inserted schedule???
    message.success("Successfully sent the invitation.");
  };

  const [open, setOpen] = useState(false);

  const showDrawer = (empId: React.Key) => {
    setselectedEmp(undefined);
    // Call the EmployeeByID Endpoit...
    const getEmployee = async () => {
      const employees = await employeeOps.getEmployeeByIdAsync(
        selectedCompany?.corporationId as number,
        empId.toString()
      );
      setselectedEmp(employees);
    };
    getEmployee();
    setOpen(true);
  };

  const onPageChange = (page: number) => {
    fetchListofEmployee(
      selectedCompany?.corporationId as number,
      page,
      deFaultPageSize!
    ).then(setEmployee);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <PageContainer
      extra={[
        <Button key="2" icon={<CloudDownloadOutlined />}>
          Download CSV
        </Button>,
        <Button
          key="1"
          icon={<PlusOutlined />}
          onClick={() => {
            setOpenAddUser(true);
          }}
          type="primary"
        >
          Add User
        </Button>,
      ]}
      title="Team members"
      subTitle="Manage team members across your organization. "
    >
      <Row gutter={16} style={{ marginTop: "32px" }}>
        <Col span={24}>
          <Table
            expandable={
              isSmallScreen
                ? {
                    expandedRowRender: (record) => (
                      <>
                        Eamil: {record.email}
                        <br />
                        <br />
                        <Space size="middle">
                          {record.status !== "Requested" ? (
                            <>
                              {record.status !== "Terminated" &&
                              userInfo?.employeeCorporation?.find(
                                (y) =>
                                  y.corporationId ===
                                  selectedCompany!.corporationId
                              )?.employeeId !== record.key ? (
                                <DeleteFilled
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    deleteEmployee(
                                      record.key.toString(),
                                      record.name
                                    );
                                  }}
                                />
                              ) : null}
                              {/*<EditOutlined
                                onClick={() => {
                                  showDrawer(record.key);
                                }}
                              />*/}
                              <InfoCircleOutlined
                                onClick={() => {
                                  showDrawer(record.key);
                                }}
                              />
                            </>
                          ) : null}
                        </Space>
                      </>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }
                : undefined
            }
            style={{ whiteSpace: "pre" }}
            columns={columns}
            dataSource={employee}
            loading={dataLoading}
            pagination={{
              pageSize: deFaultPageSize,
              total: totalResult,
              onChange: (page) => onPageChange(page),
            }}
            expandRowByClick
          />
        </Col>
      </Row>
      <AddEmployeeForm
        open={OpenAddUser}
        onCreate={onUserCreate}
        onCancel={() => {
          setOpenAddUser(false);
        }}
      />

      <Drawer
        style={{ backgroundColor: token.colorBgContainer }}
        width={640}
        placement="right"
        closable={true}
        title="Employee Profile"
        onClose={onClose}
        open={open}
      >
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Full Name"
              content={`${selectedEmp?.firstName} ${selectedEmp?.lastName}`}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Account" content={selectedEmp?.email} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="City" content="-" />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Country" content="-" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Birthday" content="February 2,1900" />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Website" content="-" />
          </Col>
        </Row>
        {selectedEmp?.invitePending && selectedEmp.isActive ? (
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                onClick={() => {
                  reInviteEmployee(selectedEmp.employeeId);
                }}
                loading={reSendInviteLoading}
              >
                Resend Invitation
              </Button>
            </Col>
          </Row>
        ) : null}
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title="Position" content={selectedEmp?.title} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Responsibilities" content="-" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Department"
              content={selectedEmp?.department?.name}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Supervisor"
              content={selectedEmp?.managerName}
            />
          </Col>
        </Row>
        <Divider />
        <Paragraph>Contacts</Paragraph>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Email" content={selectedEmp?.email} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Phone Number" content="+1 111 111 1111" />
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to={{ pathname: `/profile/${selectedEmp?.employeeId}` }}>
              <Button type="primary" onClick={() => <ProfileLayout />}>
                View Profile
              </Button>
            </Link>
          </Col>
        </Row>
      </Drawer>
    </PageContainer>
  );
};

export default Team;
