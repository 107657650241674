import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, tokenRequest } from "../auth-config";
import {
  AuthContext,
  AuthenticationContext,
  UserInfo,
} from "./authentication-context";
import LoadingState from "../components/configuration/loading-states";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

interface AuthProviderProps {
  children: ReactNode;
  setLoading: (loading: LoadingState) => void;
  loading: LoadingState;
}

const AuthenticationContextProvider = (
  props: AuthProviderProps
): JSX.Element => {
  const { setLoading, loading, children } = props;
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const acquireUserInformation = useCallback(async () => {
    fetch(`${process.env.REACT_APP_API_BASE}/v1/user/me`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        if (response.status === 204) {
          setLoading(LoadingState.NoActiveCorp);
        } else setLoading(LoadingState.Error);
      })
      .then((data: UserInfo) => {
        setUserInfo(data);
        setLoading(LoadingState.Done);
      })
      .catch(() => {
        setLoading(LoadingState.Error);
      });
  }, [accessToken]);

  const handleTokenResponse = useCallback((response: AuthenticationResult) => {
    if (!response.accessToken) throw new InteractionRequiredAuthError();
    //Call graph api here if needed.
    setAccessToken(response.accessToken);
    setLoading(LoadingState.TokenReady);
  }, []);

  const acquireToken = useCallback(async () => {
    const [account] = accounts as AccountInfo[]; // Optimized with object destructuring
    if (!account) {
      return;
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });

      // Check if the token is expired
      const { expiresOn } = response;
      if (!expiresOn || expiresOn < new Date()) {
        // Renew the token if it's expired
        return renewToken(account);
      } else {
        return handleTokenResponse(response);
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return renewToken(account);
      }
    }
  }, [accounts, handleTokenResponse, instance, tokenRequest]);

  const renewToken = async (account: AccountInfo) => {
    return instance.acquireTokenRedirect({
      ...tokenRequest,
      account,
    });
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length === 0) {
      localStorage.setItem("companySelect", "true");
      instance.loginRedirect(loginRequest);
    }
  }, [accounts, inProgress, instance]);

  useEffect(() => {
    if (
      isAuthenticated &&
      !accessToken &&
      inProgress === InteractionStatus.None
    )
      acquireToken().catch(() => {
        setLoading(LoadingState.Unauthorized);
      });
  }, [accessToken, acquireToken, inProgress, isAuthenticated]);

  useEffect(() => {
    if (
      isAuthenticated &&
      loading === LoadingState.TokenReady &&
      !userInfo &&
      accessToken
    ) {
      acquireUserInformation();
    }
  }, [isAuthenticated, userInfo, loading]);

  const values: AuthContext = useMemo(
    () => ({
      userInfo: userInfo,
      accessToken: accessToken,
      acquireToken: acquireToken,
    }),
    [userInfo, accessToken, acquireToken]
  );
  return (
    <AuthenticationContext.Provider value={values}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContextProvider;
