import React from "react";

export interface UserInfo {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  accountStatus: number;
  activeInd: boolean;
  employeeCorporation?: Array<EmployeeCorporation>;
}

export interface EmployeeCorporation {
  employeeId: string;
  corporationId: number;
}
export interface AuthContext {
  userInfo?: UserInfo;
  accessToken?: string;
  acquireToken: () => void;
}

export const AuthenticationContext = React.createContext<AuthContext | null>(
  null
);
