import { PageContainer } from "@ant-design/pro-layout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ScheduleActivity from "./schedule-activity";
import ScheduleSubDetail from "./schedule-subdetail";

const ScheduleDetail = (): JSX.Element => {
  // Incoming URL Parameter.......
  const { id } = useParams();
  const scheduleId = Number(id);

  const defaultSelectedKey = "detail";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);

  const onTabChanged = (key: React.Key) => {
    //console.log(key);
    setCurrentTab(key.toString());
  };

  const renderComponent = () => {
    switch (currentTab) {
      case "detail":
        return (
          <ScheduleSubDetail
            scheduleId={scheduleId}
            onUpdate={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        );
      case "activity":
        return (
          <ScheduleActivity
            scheduleId={scheduleId}
            onUpdate={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        );
      default:
        return (
          <ScheduleActivity
            scheduleId={scheduleId}
            onUpdate={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        );
    }
  };
  //if (showDetail || isManager) {
  return (
    <PageContainer
      tabList={[
        {
          tab: "Details",
          key: "detail",
        },
        {
          tab: "Activity",
          key: "activity",
        },
      ]}
      onTabChange={onTabChanged}
      header={{
        title: "Schedule Detail",
        subTitle:
          "Schedule detail for a given schedule, this can be used to manager schedule activity.",
        breadcrumb: {
          routes: [
            {
              path: "/home",
              breadcrumbName: "Dashboard",
            },
            {
              path: "",
              breadcrumbName: "Schedule",
            },
            {
              path: "",
              breadcrumbName: "Detail",
            },
          ],
        },
      }}
    >
      {renderComponent()}
    </PageContainer>
  );
};

export default ScheduleDetail;
