import {
  ChromeFilled,
  DashboardOutlined,
  PieChartOutlined,
  ScheduleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Schedule from "../schedule/schedule-main";
import Dashboard from "../dashboard/dashboard-main";
import Settings from "../settings/settings";
import TimeOff from "../schedule/time-off";
import Report from "../report/report-main";
import Team from "../settings/team";
import OrgLayout from "../settings/Org/orgLayout";

export default {
  route: {
    path: "/",
    routes: [
      {
        path: "/home",
        name: "Dashboard",
        icon: <DashboardOutlined />,
        component: <Dashboard />,
      },
      {
        path: "/schedule",
        name: "Schedule",
        icon: <ScheduleOutlined />,
        //access: "canAdmin",
        routes: [
          {
            path: "home",
            name: "Schedule",
            icon: "https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg",
            component: <Schedule />,
          },
          {
            path: "timeoff",
            name: "Time Off",
            icon: "https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg",
            component: <TimeOff />,
          },
        ],
      },
      {
        path: "/report",
        name: "Report",
        icon: <PieChartOutlined />,
        component: <Report />,
      },
      {
        path: "/settings",
        name: "Settings",
        icon: <SettingOutlined />,
        component: <Settings />,
        routes: [
          {
            path: " ",
            name: "Profile",
            icon: "https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg",
            component: <Settings />,
          },
          {
            path: "team",
            name: "Team",
            icon: "https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg",
            component: <Team />,
          },
          {
            path: "organization",
            name: "Organization",
            icon: "https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg",
            component: <OrgLayout />,
          },
        ],
      },
      {
        path: "https://www.mytimesystem.com/",
        name: "Help",
        icon: <ChromeFilled />,
        redirect: true,
      },
    ],
  },
  location: {
    pathname: "/",
  },
};
