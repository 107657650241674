import { promises } from "dns";
import { constants } from "../constants";
import { StringFormat } from "../Util/stringutil";
import {
  EmployeeDetail,
  InviteEmployee,
  InviteEmployeeResult,
  EmployeeDetails,
  ResponseEmployeeAvailability,
  PatchEmployeeAvailability,
} from "./entity/EmployeeOperationEntity";
import { JsonPatchDocument } from "./entity/JasonPatchDocumentEntity";
import { Status } from "./entity/Status";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface IEmployeeOperation {
  getEmployeeAsync(
    corpId: number,
    page: number,
    pageSize: number,
    all?: boolean
  ): Promise<EmployeeDetail>;

  getEmployeeByIdAsync(
    corpId: number,
    employeeId: string
  ): Promise<EmployeeDetails>;

  inviteEmployeeAsync(inviteEmp: InviteEmployee): Promise<InviteEmployeeResult>;

  reInviteEmployeeAsync(
    corpId: number,
    employeeId: string
  ): Promise<InviteEmployeeResult>;

  deleteEmployeeAsync(corpId: number, employeeId: string): Promise<boolean>;

  getEmployeeAvailabilityAsync(
    corpId: number,
    employeeId: string
  ): Promise<Status<ResponseEmployeeAvailability[]>>;

  patchEmployeeAvailabilityAsync(
    corpId: number,
    patchCorporationRequest: JsonPatchDocument<PatchEmployeeAvailability>[]
  ): Promise<Status<null>>;
}

export class EmployeeOperation implements IEmployeeOperation {
  async patchEmployeeAvailabilityAsync(
    corpId: number,
    patchCorporationRequest: JsonPatchDocument<PatchEmployeeAvailability>[]
  ): Promise<Status<null>> {
    const response = await myFetch<Status<null>>(
      `${constants.BASE_URL}${StringFormat(
        constants.PATCH_EMPLOYEE_AVAILABILITY,
        corpId
      )}`,
      {
        method: "PATCH",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(patchCorporationRequest),
      }
    );

    if (!response.ok) {
      //const errorData = await response.json();
      return Promise.reject(new Error(`Error prosessing request!}`));
    }

    const data = await response.json();
    return data;
  }
  async getEmployeeAvailabilityAsync(
    corpId: number,
    employeeId: string
  ): Promise<Status<ResponseEmployeeAvailability[]>> {
    const response = await myFetch<Status<ResponseEmployeeAvailability[]>>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_EMPLOYEE_AVAILABILITY,
        corpId,
        employeeId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
  async deleteEmployeeAsync(
    corpId: number,
    employeeId: string
  ): Promise<boolean> {
    const response = await myFetch<null>(
      `${constants.BASE_URL}${StringFormat(
        constants.DELETE_EMPLOYEE,
        corpId,
        employeeId
      )}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response;

    if (data.status === 204) return true;
    return false;
  }

  async reInviteEmployeeAsync(
    corpId: number,
    employeeId: string
  ): Promise<InviteEmployeeResult> {
    const response = await myFetch<InviteEmployeeResult>(
      `${constants.INVITE_BASE_URL}${StringFormat(
        constants.REINVITE_EMPLOYEE_ENDPOINT,
        corpId,
        employeeId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );
    const data = await response.json();
    return data;
  }

  async inviteEmployeeAsync(
    inviteEmp: InviteEmployee
  ): Promise<InviteEmployeeResult> {
    const response = await myFetch<InviteEmployeeResult>(
      `${constants.INVITE_BASE_URL}${constants.INVITE_EMPLOYEE_ENDPOINT}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
        }),
        body: JSON.stringify(inviteEmp),
      }
    );

    const data = await response.json();
    return data;
  }

  async getEmployeeByIdAsync(
    corpId: number,
    employeeId: string
  ): Promise<EmployeeDetails> {
    const response = await myFetch<EmployeeDetails>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_EMPLOYEE_BY_ID,
        corpId,
        employeeId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async getEmployeeAsync(
    corpId: number,
    page: number,
    pageSize: number,
    all?: boolean
  ): Promise<EmployeeDetail> {
    const response = await myFetch<EmployeeDetail>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_EMPLOYEE,
        corpId
      )}?page=${page}&pageSize=${pageSize}&all=${all ?? false}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
}
