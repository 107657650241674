import { SmileOutlined } from "@ant-design/icons";
import { Button, Result, Image } from "antd";
import { Link } from "react-router-dom";
import Construction_Hulk from "../images/Construction_Hulk.png";

const UnderConstruction = (): JSX.Element => {
  const image =
    process.env.NODE_ENV === "development" ? (
      <Image width={500} src={Construction_Hulk} />
    ) : (
      <SmileOutlined />
    );
  return (
    <Result
      icon={image}
      title="Page is under construction, check back later."
      extra={
        <Link to="/home">
          <Button type="primary">Home</Button>
        </Link>
      }
    />
  );
};

export default UnderConstruction;
