import { StringFormat } from "../Util/stringutil";
import { constants } from "../constants";
import {
  CorpDetailAPIEntity,
  InsertCorpAPIEntity,
  InsertCorpReturnAPIEntity,
  InvoiceData,
  PatchCorpRequest,
} from "./entity/CorporationOperationEntity";
import { JsonPatchDocument } from "./entity/JasonPatchDocumentEntity";
import { Status } from "./entity/Status";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface CorporationDataSource {
  getCorpByIdAsync(corpId: number): Promise<Status<CorpDetailAPIEntity>>;
  getCorpAsync(): Promise<Status<CorpDetailAPIEntity[]>>;
  postCorp(
    corpInsert: InsertCorpAPIEntity
  ): Promise<Status<InsertCorpReturnAPIEntity>>;
  patchCorp(
    corpId: number,
    patchCorporationRequest: JsonPatchDocument<PatchCorpRequest>[]
  ): Promise<Status<null>>;
  getInvoiceAsync(corpId: number): Promise<Status<InvoiceData>>;
  getSubscriptionAsync(corpId: number): Promise<Status<InvoiceData>>;
}

export class CorporationOperation implements CorporationDataSource {
  async getSubscriptionAsync(corpId: number): Promise<Status<InvoiceData>> {
    const response = await myFetch<Status<InvoiceData>>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_CORPSUBSCRIPTION,
        corpId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer BLHA`,
        }),
      }
    );
    const data = await response.json();
    return data;
  }
  async getInvoiceAsync(corpId: number): Promise<Status<InvoiceData>> {
    const response = await myFetch<Status<InvoiceData>>(
      `${constants.BASE_URL}${StringFormat(constants.GET_CORPINVOICE, corpId)}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer BLHA`,
        }),
      }
    );
    const data = await response.json();
    return data;
  }

  async postCorp(
    corpInsert: InsertCorpAPIEntity
  ): Promise<Status<InsertCorpReturnAPIEntity>> {
    const response = await myFetch<Status<InsertCorpReturnAPIEntity>>(
      `${constants.BASE_URL}${StringFormat(constants.POST_CORP)}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(corpInsert),
      }
    );

    const data = await response.json();
    return data;
  }

  async patchCorp(
    corpId: number,
    patchCorporationRequest: JsonPatchDocument<PatchCorpRequest>[]
  ): Promise<Status<null>> {
    const response = await myFetch<Status<null>>(
      `${constants.BASE_URL}${StringFormat(constants.PATCH_CORP, corpId)}`,
      {
        method: "PATCH",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(patchCorporationRequest),
      }
    );

    const data = await response.json();
    return data;
  }
  /*accessToken: string;

  constructor(accessToken: string) {
    //TODO: look up an alternative solution for initially null context props
    this.accessToken = accessToken;
  }*/
  // Get Corpration detail by Id..
  async getCorpByIdAsync(corpId: number): Promise<Status<CorpDetailAPIEntity>> {
    const response = await myFetch<Status<CorpDetailAPIEntity>>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_CORP_BY_ID_ENDPOINT,
        corpId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer BLHA`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  // This will get all Corporation Associated to an logged in user.
  async getCorpAsync(): Promise<Status<CorpDetailAPIEntity[]>> {
    const response = await myFetch<Status<CorpDetailAPIEntity[]>>(
      `${constants.BASE_URL}${constants.GET_CORP_ENDPOINT}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer BLHA`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
}
