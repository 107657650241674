import { Tag, Typography } from "antd";

const { Title, Paragraph } = Typography;

const Legend = () => {
  return (
    <div style={{ margin: "20px" }}>
      <Paragraph>
        Use the following colors to identify different state of the schedule:
      </Paragraph>
      <Tag color="red">Time Off</Tag>
      <Tag color="green">Scheduled</Tag>
      <Tag color="orange">Pending Time Off Approval</Tag>
    </div>
  );
};

export default Legend;
