//Example at : https://codesandbox.io/s/ws5dtr?file=/App.tsx:2254-2763
import { PlusOutlined } from "@ant-design/icons";
import {
  ProColumns,
  ActionType,
  ProTable,
  PageContainer,
} from "@ant-design/pro-components";
import { Space, Tag, Button, Avatar } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import {
  Employee,
  EmployeeActivity,
  ScheduleReportQuery,
} from "../../serviceoperation/entity/ScheduleOperationEntity";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import { getFirstAndLastDayOfWeek } from "../../Util/dateutil";
import { EmployeeDirectReports } from "../../repository/EmployeeRepositoryEntity";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";

type ScheduleHistory = {
  employee: Employee;
  scheduleId: React.Key;
  title: string;
  note?: string;
  scheduleDate: string;
  startTime: string;
  endTime: string;
  employeeActivity?: EmployeeActivity;
};

const Report = (): JSX.Element => {
  const { selectedCompany } = useContext(CompanyContext);
  const [history, setHistory] = useState<ScheduleHistory[]>([]);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const employeeOps = new EmployeeOperation();
  const [queryOptions, setQueryOptions] = useState<ScheduleReportQuery>();
  const [totalResult, setTotalResult] = useState<number>(0);
  const [deFaultPageSize] = useState<number>(25);
  const actionRef = useRef<ActionType>();
  const [empList, setEmployeeList] = useState<EmployeeDirectReports[]>([]);
  const [isManager, setIsManager] = useState<boolean>(false);

  useEffect(() => {
    const getEmployeeList = async () => {
      if (!selectedCompany) return;
      const employees = await employeeOps.getEmployeeAsync(
        selectedCompany.corporationId,
        1,
        255
      );
      setEmployeeList(
        employees.results
          .filter((x) => x.isActive)
          .map((employee) => ({
            label: `${employee.firstName} ${employee.lastName}`,
            value: employee.employeeId,
          }))
      );
      setIsManager(employees.rowCount > 1);
    };
    getEmployeeList();
  }, [selectedCompany]);

  //const empList = getEmployeeDirectReports(selectedCompany, true);
  const scheduleOps = new ScheduleOperation();

  const date = new Date(); // use any date here
  const [firstDayOfWeek, lastDayOfWeek] = getFirstAndLastDayOfWeek(date);

  const col: ProColumns<ScheduleHistory>[] = [
    {
      disable: false,
      title: "Employee",
      dataIndex: "employee",
      hideInSearch: true,
      render(text, record) {
        return (
          <Space>
            <Avatar
              src={
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg"
                  alt="avatar"
                />
              }
            />
            <div>
              {record.employee.firstName} {record.employee.lastName}
              <br />
              {record.employee.title}
            </div>
          </Space>
        );
      },
      filters: empList.map((s) => ({
        text: s.label,
        value: s.value,
      })),
      onFilter: (value, record) => record.employee.employeeID === value,
    },
    {
      disable: false,
      filters: false,
      hideInSearch: true,
      title: "Title",
      dataIndex: "title",
    },
    {
      disable: false,
      hideInSearch: true,
      title: "Notes",
      dataIndex: "notes",
    },
    {
      disable: true,
      title: "Activity",
      dataIndex: "employeeActivity",
      search: false,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) =>
        record?.startTime ? (
          <div>
            Clocked In: {record?.startTime}
            <br />
            Clocked Out: {record?.endTime}
          </div>
        ) : null,
    },
    {
      disable: true,
      title: "Hours Worked",
      dataIndex: "employeeActivity",
      search: false,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => (
        <Space>
          <Tag color="green">{record.employeeActivity?.totalHoursWorked}</Tag>
        </Space>
      ),
    },
    {
      title: "Schedule Date",
      dataIndex: "scheduleDate",
      valueType: "date",
      //sorter: true,
      hideInSearch: true,
      sorter: (a, b) => b.scheduleDate.localeCompare(a.scheduleDate),
      //sorter: (a: any, b: any) => a.scheduleDate - b.scheduleDate,
    },
    {
      disable: false,
      title: "Range",
      dataIndex: "scheduleDate",
      valueType: "dateRange",
      initialValue: [firstDayOfWeek, lastDayOfWeek],
      hideInTable: true,
      search: {
        transform: (value) => {
          return {
            startTime: value[0],
            endTime: value[1],
          };
        },
      },
    },
    {
      disable: false,
      title: "Employee",
      hideInForm: true,
      hideInTable: true,
      dataIndex: "selectedEmpId",
      valueType: "select",
      //render: (_, record) => <Select options={emp} />,
      request: async () => empList,
    },
  ];

  const fetchScheduleReport = async (options: ScheduleReportQuery) => {
    return await scheduleOps.getScheduleReportAsync(
      selectedCompany?.corporationId as number,
      options
    );
  };

  const getFormattedDateTime = (dateTimeString?: string) => {
    if (dateTimeString) {
      return `${new Date(dateTimeString + "Z").toLocaleString()}`;
    }
    return "";
  };

  const fetchScheduleReportData = async (options: ScheduleReportQuery) => {
    const response = await fetchScheduleReport(options);
    //.then((res) => setTotalResult(res.data.rowCount))
    /*.then((response) =>
        //setTotalResult(response.data.rowCount);
        response?.data?.results?.map((result) => ({
          employee: result.employee,
          scheduleId: result.scheduleId,
          title: result.title,
          note: result.note,
          scheduleDate: result.scheduleDate,
          startTime: getFormattedDateTime(result.employeeActivity?.clockedInAt),
          endTime: getFormattedDateTime(result.employeeActivity?.clockedOutAt),
          employeeActivity: result.employeeActivity,
        }))
      );*/
    setTotalResult(response.data.rowCount);
    return response?.data?.results?.map((result) => ({
      employee: result.employee,
      scheduleId: result.scheduleId,
      title: result.title,
      note: result.note,
      scheduleDate: result.scheduleDate,
      startTime: getFormattedDateTime(result.employeeActivity?.clockedInAt),
      endTime: getFormattedDateTime(result.employeeActivity?.clockedOutAt),
      employeeActivity: result.employeeActivity,
    }));
  };

  useEffect(() => {
    const options: ScheduleReportQuery = {
      weekOf: new Date().toDateString(),
      page: 1,
      pageSize: deFaultPageSize,
      IncludeDirReport: true,
    };
    setQueryOptions(options);
    fetchScheduleReportData(options).then(setHistory);
  }, [selectedCompany, userInfo]);

  const handleQuerySubmit = (value: any) => {
    const startDate =
      value.startTime != "[object Object]"
        ? value.startTime
        : firstDayOfWeek.toDateString();
    const endDate =
      value.endTime != "[object Object]"
        ? value.endTime
        : lastDayOfWeek.toDateString();
    const employeeId = value.selectedEmpId;
    const options: ScheduleReportQuery = {
      startDate,
      endDate,
      page: 1,
      pageSize: deFaultPageSize,
      IncludeDirReport: true,
    };

    if (employeeId && employeeId.trim() !== "") {
      options.employeeId = employeeId;
    }
    setQueryOptions(options);
    fetchScheduleReportData(options).then(setHistory);
  };

  const onPageChange = (page: number) => {
    queryOptions!.page = page;
    fetchScheduleReportData(queryOptions!).then(setHistory);
  };

  useEffect(() => {
    actionRef.current?.reload();
    actionRef.current!.reset!();
  }, [empList]);

  return (
    <PageContainer subTitle="Effortlessly generate insightful reports for past occurrences of your schedule.">
      <ProTable<ScheduleHistory>
        columns={col}
        actionRef={actionRef}
        cardBordered
        dataSource={history}
        editable={{
          type: "multiple",
        }}
        summary={() => (
          <>
            {history ? (
              <ProTable.Summary fixed={"bottom"}>
                <ProTable.Summary.Row>
                  <ProTable.Summary.Cell index={0} colSpan={4}>
                    Total Hours
                  </ProTable.Summary.Cell>
                  <ProTable.Summary.Cell index={1} colSpan={4}>
                    {history
                      .filter((item) => item.employeeActivity?.totalHoursWorked)
                      .reduce(
                        (sum, current) =>
                          sum + current.employeeActivity!.totalHoursWorked!,
                        0
                      )}{" "}
                    Hours
                  </ProTable.Summary.Cell>
                </ProTable.Summary.Row>
              </ProTable.Summary>
            ) : null}
          </>
        )}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
          onChange(value) {
            //console.log("value: ", value);
          },
        }}
        rowKey="id"
        search={{
          labelWidth: "auto",
        }}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        // On Query Submit Button
        onSubmit={(value) => {
          handleQuerySubmit(value);
        }}
        pagination={{
          pageSize: deFaultPageSize,
          total: totalResult,
          onChange: (page) => onPageChange(page),
        }}
        dateFormatter="string"
        headerTitle="Schedule History"
        toolBarRender={() => [
          isManager ? (
            <Button key="button" icon={<PlusOutlined />} type="primary">
              Add Schedule
            </Button>
          ) : null,
        ]}
      />
    </PageContainer>
  );
};

export default Report;
