import { useAccount, useMsal } from "@azure/msal-react";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import LoadingState from "../components/configuration/loading-states";
import { CorporationOperation } from "../serviceoperation/CorporationOperation";
import { CorpDetailAPIEntity } from "../serviceoperation/entity/CorporationOperationEntity";
import { getAccessToken } from "../Util/msalHelper";
import { useMSALInterceptor } from "../Util/useMSALInterceptor";
import { AuthenticationContext } from "./authentication-context";
import { CompanyContext, ICompanyContext } from "./company-context";
import { message } from "antd";

interface CompanyContextProviderProps {
  children: ReactNode;
  setLoading: (loading: LoadingState) => void;
}

const CompanyContextProvider = (props: CompanyContextProviderProps) => {
  // Token Interceptor..
  // This will handle the tokens. :D

  // Remove this, and call the token directly in the service layer.
  useMSALInterceptor();

  const { children, setLoading } = props;
  const [companyData, setCompanyData] = useState<Array<CorpDetailAPIEntity>>(
    JSON.parse(localStorage.getItem("CompanyData") ?? "[]")
  );
  const [selectedCompany, setSelectedCompany] = useState<CorpDetailAPIEntity>(
    JSON.parse(localStorage.getItem("selectedCompany") ?? "{}")
  );

  // This will fetch the Company and Selected Company data
  // from Local Storage.
  useEffect(() => {
    const selectedComp = JSON.parse(
      localStorage.getItem("selectedCompany") || "{}"
    );
    const compData = JSON.parse(localStorage.getItem("CompanyData") || "[]");
    setSelectedCompany(selectedComp);
    setCompanyData(compData);
  }, []);

  const userCompanies = useContext(AuthenticationContext)?.userInfo
    ?.employeeCorporation;

  //const accessToken = useContext(AuthenticationContext)?.accessToken;

  const updateSelectedCompany = (companyId: number) => {
    const newCompany = companyData.find(
      (comp) => comp.corporationId === companyId
    );
    if (newCompany) setSelectedCompany(newCompany);
  };

  const values: ICompanyContext = useMemo(
    () => ({ companyData, selectedCompany, updateSelectedCompany }),
    [companyData, selectedCompany]
  );

  useEffect(() => {
    const saveDataToLocalStorage = (key: string, data: any) => {
      if (data) {
        localStorage.setItem(key, JSON.stringify(data));
      }
    };

    saveDataToLocalStorage("selectedCompany", selectedCompany);
    saveDataToLocalStorage("CompanyData", companyData);
  }, [selectedCompany, companyData]);

  useEffect(() => {
    if (userCompanies && userCompanies.length > 0 && companyData.length === 0) {
      const compOps = new CorporationOperation();
      compOps
        .getCorpAsync()
        .then((data) => {
          if (data.statusCode === 200) {
            if (data.data) {
              setCompanyData(data.data);
              setSelectedCompany(data.data[0]);
            }
          } else {
            message.error("An error has occured trying to fetch corporation.");
          }
        })
        .catch(() => {
          setLoading(LoadingState.Error);
        });
    }
  }, [userCompanies, companyData]);
  return (
    <CompanyContext.Provider value={values}>{children}</CompanyContext.Provider>
  );
};

export default CompanyContextProvider;
