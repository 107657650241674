import { Avatar, Card, Col, Menu, Row, Space, Tag } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import Title from "antd/es/typography/Title";
import { PageContainer } from "@ant-design/pro-layout";
import Notification from "./notification";
import ProfileDetail from "./profileDetail";
import { useParams } from "react-router";
import { EmployeeDetails } from "../../serviceoperation/entity/EmployeeOperationEntity";

const ProfileLayout = () => {
  // Incoming URL Parameter.......
  const { id } = useParams();
  const empId = id ? String(id) : undefined;

  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { selectedCompany } = useContext(CompanyContext);
  const [userProfile, setUserProfile] = useState<EmployeeDetails>();
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const defaultSelectedKey = "profileDetail";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);
  const [items, setItems] = useState<any>([
    {
      label: "Profile",
      key: "profileDetail",
    },
    {
      label: "Notification",
      key: "notification",
    },
  ]);

  const handleTabChange = (event: any) => {
    setCurrentTab(event.key);
  };

  useMemo(() => {
    const employeeOps = new EmployeeOperation();
    const loggedInUserID = userInfo?.employeeCorporation
      ?.filter((y) => y.corporationId === selectedCompany?.corporationId)
      .map((y) => y.employeeId)
      .at(0);
    //CurrentlY Logged In user same as the Schedule Id User??

    employeeOps
      .getEmployeeByIdAsync(
        selectedCompany?.corporationId as number,
        empId ? empId : loggedInUserID!
      )
      .then((x) => {
        setUserProfile(x);
      });
  }, [selectedCompany, userInfo]);

  const renderComponent = () => {
    switch (currentTab) {
      case "profileDetail":
        return <ProfileDetail empDetails={userProfile!} />;
      case "notification":
        return <Notification />;
      default:
        return <ProfileDetail empDetails={userProfile!} />;
    }
  };
  return (
    <PageContainer subTitle="Settings to configure your basic details, and notifications.">
      <Row gutter={16}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
        >
          <Card size="default">
            <div style={{ marginTop: "8px", textAlign: "right" }}>
              <Tag
                color={
                  userProfile?.invitePending && userProfile?.isActive
                    ? "blue"
                    : userProfile?.isActive
                    ? "green"
                    : "volcano"
                }
              >
                {userProfile?.invitePending && userProfile?.isActive
                  ? "Status: Pending Invitation"
                  : userProfile?.isActive
                  ? "Status: Active"
                  : "Status: Terminated"}
              </Tag>
            </div>
            <Row>
              <Col span={24}>
                <Space.Compact
                  block
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    src={
                      <img
                        src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                        alt="avatar"
                      />
                    }
                  />
                </Space.Compact>
                <br></br>
                <Space.Compact
                  block
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Title level={4}>
                    {userProfile?.firstName} {userProfile?.lastName}
                  </Title>
                </Space.Compact>
                <Space.Compact
                  block
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {userProfile?.title}
                </Space.Compact>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
        >
          <Card size="default">
            <Menu
              onClick={handleTabChange}
              mode="horizontal"
              defaultSelectedKeys={[defaultSelectedKey]}
              selectedKeys={[currentTab]}
              items={items}
              style={{ marginTop: -12 }}
            />
            {renderComponent()}
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default ProfileLayout;
