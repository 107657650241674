import React, { useEffect, useState } from "react";
import type { TableColumnsType } from "antd";
import { Table, Typography } from "antd";
import {
  AddBulkScheduleSummary,
  BulkSchedule,
  ScheduleNormalized,
} from "./schedule-bulkadd-entity";
import { calculateTotalHours } from "../../../Util/calculation";

interface DataType {
  key: React.Key;
  name: string;
  department: string;
  branch: string;
  createdAt: string;
}

type ExpandedDataType = {
  key: React.Key;
  date: string;
  startTime: string;
  endTime: string;
  title: string;
  description: string;
  hours: number;
};

const columns: TableColumnsType<DataType> = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Department", dataIndex: "department", key: "department" },
  { title: "Branch", dataIndex: "branch", key: "branch" },
  { title: "Created Date", dataIndex: "createdAt", key: "createdAt" },
];

const expandedRowRender = (
  scheduleData: BulkSchedule[],
  parentKey: React.Key
) => {
  const childColumns: TableColumnsType<ExpandedDataType> = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Start Time", dataIndex: "startTime", key: "startTime" },
    { title: "End Time", dataIndex: "endTime", key: "endTime" },
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Hours", dataIndex: "hours", key: "hours" },
  ];

  const expandedData: ExpandedDataType[] = [];

  scheduleData
    .filter((item) => item.name === parentKey)
    .forEach((bulkSchedule) => {
      bulkSchedule.schedule.forEach((schedule) => {
        expandedData.push({
          key: bulkSchedule.key,
          date: schedule.date,
          startTime: `${schedule.startHour}:${schedule.startMin}`,
          endTime: `${schedule.endHour}:${schedule.endMin}`,
          title: schedule.title,
          description: schedule.description,
          hours: calculateTotalHours(
            `${schedule.startHour}:${schedule.startMin}`,
            `${schedule.endHour}:${schedule.endMin}`
          ),
        });
      });
    });

  const totalHours = expandedData.reduce(
    (sum, record) => sum + record.hours,
    0
  );

  const summaryRow = () => (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}></Table.Summary.Cell>
      <Table.Summary.Cell index={1}></Table.Summary.Cell>
      <Table.Summary.Cell index={2}></Table.Summary.Cell>
      <Table.Summary.Cell index={3}></Table.Summary.Cell>
      <Table.Summary.Cell index={4}>Total Hours:</Table.Summary.Cell>
      <Table.Summary.Cell index={5}>{totalHours}</Table.Summary.Cell>
    </Table.Summary.Row>
  );

  return (
    <Table
      columns={childColumns}
      dataSource={expandedData}
      summary={summaryRow}
      pagination={false}
    />
  );
};

const ScheduleBulkAddSummary: React.FC<AddBulkScheduleSummary> = ({
  scheduleData,
  employeeData,
  employeeDetails,
  selectedWeek,
  onSuccess,
}) => {
  const { Title, Paragraph } = Typography;
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const transformedData: BulkSchedule[] = scheduleData.map((item) => {
    const schedule: ScheduleNormalized[] = [];
    const addScheduleEntry = (
      day: string,
      startHour: string,
      startMin: string,
      endHour: string,
      endMin: string
    ) => {
      schedule.push({
        date: day,
        startHour: startHour || "",
        startMin: startMin || "",
        endHour: endHour || "",
        endMin: endMin || "",
        title: "",
        description: "",
        //This should never be null
        branchId: employeeDetails
          .filter((x) => x.employeeId === item.name)
          .map((y) => y.organization!.branchId)
          .at(0)!,
      });
    };

    const empBranch = employeeDetails
      .filter((x) => x.employeeId === item.name)
      .map((y) => y.organization!.branchId);

    if (item.schedulesunday && (!item.schedulesunday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[0],
        item.schedulesunday[0]?.format("HH"),
        item.schedulesunday[0]?.format("mm"),
        item.schedulesunday[1]?.format("HH"),
        item.schedulesunday[1]?.format("mm")
      );
    }

    if (item.schedulemonday && (!item.schedulemonday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[1],
        item.schedulemonday[0]?.format("HH"),
        item.schedulemonday[0]?.format("mm"),
        item.schedulemonday[1]?.format("HH"),
        item.schedulemonday[1]?.format("mm")
      );
    }

    if (item.scheduletuesday && (!item.scheduletuesday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[2],
        item.scheduletuesday[0]?.format("HH"),
        item.scheduletuesday[0]?.format("mm"),
        item.scheduletuesday[1]?.format("HH"),
        item.scheduletuesday[1]?.format("mm")
      );
    }

    if (item.schedulewednesday && (!item.schedulewednesday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[3],
        item.schedulewednesday[0]?.format("HH"),
        item.schedulewednesday[0]?.format("mm"),
        item.schedulewednesday[1]?.format("HH"),
        item.schedulewednesday[1]?.format("mm")
      );
    }

    if (item.schedulethursday && (!item.schedulethursday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[4],
        item.schedulethursday[0]?.format("HH"),
        item.schedulethursday[0]?.format("mm"),
        item.schedulethursday[1]?.format("HH"),
        item.schedulethursday[1]?.format("mm")
      );
    }

    if (item.schedulefriday && (!item.schedulefriday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[5],
        item.schedulefriday[0]?.format("HH"),
        item.schedulefriday[0]?.format("mm"),
        item.schedulefriday[1]?.format("HH"),
        item.schedulefriday[1]?.format("mm")
      );
    }

    if (item.schedulesaturday && (!item.schedulesaturday[2] ?? false)) {
      addScheduleEntry(
        selectedWeek[6],
        item.schedulesaturday[0]?.format("HH"),
        item.schedulesaturday[0]?.format("mm"),
        item.schedulesaturday[1]?.format("HH"),
        item.schedulesaturday[1]?.format("mm")
      );
    }
    return {
      key: item.key,
      name: item.name,
      schedule: schedule,
    };
  });

  // After the data transformation
  // set the onSuccess to signal the parent componenet.
  const [onSuccessCalled, setOnSuccessCalled] = useState(false);

  useEffect(() => {
    if (!onSuccessCalled) {
      // Call onSuccess if it hasn't been called already
      onSuccess(transformedData, null);
      setOnSuccessCalled(true); // Set the flag to true
    }
  }, [scheduleData, onSuccessCalled]);

  const handleExpand = (expanded: boolean, record: DataType) => {
    if (expanded) {
      // If expanded, add the key to the expandedRowKeys array
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.key]);
    } else {
      // If collapsed, remove the key from the expandedRowKeys array
      setExpandedRowKeys((prevKeys) =>
        prevKeys.filter((key) => key !== record.key)
      );
    }
  };

  const data: DataType[] = employeeDetails
    .filter((z) => scheduleData.map((sch) => sch.name).includes(z.employeeId))
    .map((x) => ({
      key: x.employeeId,
      name: `${x.firstName} ${x.lastName}`,
      department: x.title,
      branch: x.organization?.branchName || "",
      createdAt: new Date().toDateString(),
    }));

  return (
    <>
      <Paragraph style={{ marginTop: "12px" }}>
        Please take a moment to carefully review your choice before proceeding.
        After you click 'Submit,' the schedule will become accessible to your
        colleagues.
      </Paragraph>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) =>
            expandedRowRender(transformedData, record.key),
          onExpand: handleExpand,
          expandedRowKeys,
        }}
        dataSource={data}
        size="small"
      />
    </>
  );
};

export default ScheduleBulkAddSummary;
