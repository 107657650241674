import { RouteObject, useRoutes } from "react-router-dom";
import NotFound from "./errors/not-found";
import Dashboard from "./components/dashboard/dashboard-main";
import CustomLayout from "./components/shared/layout";
import Timesheets from "./components/timesheets/timesheets-main";
import Schedule from "./components/schedule/schedule-main";
import ScheduleDetail from "./components/schedule/schedule-detail";
import TimeOff from "./components/schedule/time-off";
import Report from "./components/report/report-main";
import TimeOffManager from "./components/schedule/timeoff/time-off-manager";
import Team from "./components/settings/team";
import ProfileLayout from "./components/settings/profileLayout";
import ScheduleBulkAddLayout from "./components/schedule/scheduleAdd/schedule-bulkadd-layout";
import NewCorporationLayout from "./components/corporation/corp-new-layout";
import DayView from "./components/dashboard/dayview";
import OrgLayout from "./components/settings/Org/orgLayout";

const AppRouter = () => {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <CustomLayout />,
      children: [
        // The index route defines what should be displayed
        // on the default route i.e. '/'
        { index: true, element: <Dashboard /> },
        { path: "/home", element: <Dashboard /> },
        { path: "/timesheets", element: <Timesheets /> },
        { path: "/schedule", element: <Schedule /> },
        { path: "/schedule/home", element: <Schedule /> },
        { path: "/schedule/:id", element: <ScheduleDetail /> },
        { path: "/schedule/bulkadd", element: <ScheduleBulkAddLayout /> },
        { path: "/schedule/timeoff", element: <TimeOff /> },
        { path: "/schedule/timeoff/manager", element: <TimeOffManager /> },
        { path: "/settings", element: <ProfileLayout /> },
        { path: "/profile/:id", element: <ProfileLayout /> },
        { path: "/settings/team", element: <Team /> },
        { path: "/settings/organization", element: <OrgLayout /> },
        { path: "/report", element: <Report /> },
        { path: "/join", element: <NewCorporationLayout /> },
        { path: "/day", element: <DayView /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ];

  return useRoutes(routes);
};

export default AppRouter;
