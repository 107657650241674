import { constants } from "../constants";
import { StringFormat } from "../Util/stringutil";
import { ReadBranchDetails } from "./entity/BranchOperationEntity";
import { Status } from "./entity/Status";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface IBranchOperation {
  getBranchAsync(corpId: number): Promise<Status<ReadBranchDetails[]>>;
  getBranchByIdAsync(
    corpId: number,
    branchId: number
  ): Promise<ReadBranchDetails>;
}

export class BranchOperation implements IBranchOperation {
  // Get Branch Detail By Id.
  async getBranchByIdAsync(
    corpId: number,
    branchId: number
  ): Promise<ReadBranchDetails> {
    throw new Error("Method not implemented.");
  }

  // Get Corpration detail by Id..
  async getBranchAsync(corpId: number): Promise<Status<ReadBranchDetails[]>> {
    const response = await myFetch<Status<ReadBranchDetails[]>>(
      `${constants.BASE_URL}${StringFormat(constants.GET_ALL_BRANCH, corpId)}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
}
