import { Card, Col, Descriptions, List, Row, Typography, Image } from "antd";
import { useContext, useEffect } from "react";
import { CompanyContext } from "../contexts/company-context";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { useToken } from "@ant-design/pro-components";
import { logout } from "../Util/logout";

//import { Button, Result, Image } from "antd";

import { ReactComponent as MyImage } from "../images/MyTime_White.svg";
import { useAccount, useMsal } from "@azure/msal-react";
import Construction_Hulk from "../images/noCompany.png";

interface CorporationSelectProps {
  handleCompanyUpdate: (requireCompanySelect: boolean) => void;
}
const CorporationSelect = (props: CorporationSelectProps) => {
  const { handleCompanyUpdate } = props;
  const { updateSelectedCompany, companyData } = useContext(CompanyContext);
  const { token } = useToken();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const handleLogout = () => logout(instance, account);

  const handleCompanySelect = (value: number) => {
    localStorage.setItem("companySelect", "false");
    updateSelectedCompany(value);
    handleCompanyUpdate(false);
  };

  // If there is only one corporation
  // then auto select that.
  useEffect(() => {
    if (companyData.length === 1) {
      localStorage.setItem("companySelect", "false");
      handleCompanySelect(companyData[0].corporationId);
      handleCompanyUpdate(false);
    }
  }, [companyData]);

  const { Title, Paragraph, Text } = Typography;

  const CustomEmptyList = () => (
    <div style={{ textAlign: "center", padding: "24px" }}>
      <Image width={500} src={Construction_Hulk} />
      <Paragraph>Looks like you don't belong to any corporation.</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>
        <a onClick={handleLogout}>Log Out</a>
      </Paragraph>
    </div>
  );

  return (
    <PageContainer
      style={{
        backgroundColor: token.colorBgBase,
        padding: 24,
        margin: 0,
        width: "100%",
        height: "100vh",
      }}
    >
      <ProCard
        style={{ marginTop: 24, backgroundColor: token.colorBgContainer }}
      >
        <Row justify="center" align="middle">
          {/*<Col xs={24} lg={12}>
            <Title level={2} style={{ textAlign: "center", margin: "24px 0" }}>
              Welcome to myTimeverse!
            </Title>
            <Paragraph style={{ textAlign: "center" }}>
              Please select your organization.
            </Paragraph>
            <List
              style={{
                alignContent: "center",
                alignItems: "center",
                margin: "24px",
              }}
              size="small"
              dataSource={companyData}
              renderItem={(item) => (
                <List.Item
                  style={{
                    textAlign: "center",
                    padding: "24px 0 ",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCompanySelect(item.corporationId)}
                >
                  <Card title={item.name}>{item.description}</Card>
                </List.Item>
              )}
            />
          </Col>*/}
          <Col xs={24} lg={12}>
            <div style={{ textAlign: "center", margin: "24px 0" }}>
              <MyImage width={356} height={128} />{" "}
              {/* Adjust the width and height as needed */}
            </div>

            <Paragraph style={{ textAlign: "center" }}>
              Please select your organization.
            </Paragraph>
            <List
              grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
              size="small"
              dataSource={companyData}
              renderItem={(item) => (
                <List.Item
                  style={{
                    textAlign: "center",
                    padding: "24px 0 ",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCompanySelect(item.corporationId)}
                >
                  <Card title={item.name}>{item.description}</Card>
                </List.Item>
              )}
              locale={{ emptyText: <CustomEmptyList /> }}
            />
          </Col>
        </Row>
      </ProCard>
    </PageContainer>
  );
};

export default CorporationSelect;
