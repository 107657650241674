import { Menu } from "antd";
import { useState } from "react";
import CompanyLevel from "./company-level";
import TimesheetErrors from "./timesheet-errors";
import TimesheetTable from "./timesheets-table";

const Timesheets = (): JSX.Element => {
  const defaultSelectedKey = "timesheets";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);

  const handleTabChange = (event: any) => {
    setCurrentTab(event.key);
  };

  const renderComponent = () => {
    switch (currentTab) {
      case "timesheets":
        return <TimesheetTable />;
      case "errors":
        return <TimesheetErrors />;
      case "companyLevel":
        return <CompanyLevel />;
      default:
        return <TimesheetTable />;
    }
  };

  return (
    <>
      <Menu
        onClick={handleTabChange}
        mode="horizontal"
        defaultSelectedKeys={[defaultSelectedKey]}
        selectedKeys={[currentTab]}
      >
        <Menu.Item key="timesheets">
          <span>Timesheets</span>
        </Menu.Item>
        <Menu.Item key="errors">
          <span>Errors</span>
        </Menu.Item>
        <Menu.Item key="companyLevel">
          <span>Company Level</span>
        </Menu.Item>
      </Menu>
      {renderComponent()}
    </>
  );
};

export default Timesheets;
