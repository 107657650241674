import { constants } from "../constants";
import { StringFormat } from "../Util/stringutil";
import { ReadBranchDetails } from "./entity/BranchOperationEntity";
import { DepartmentResult } from "./entity/DepartmentOperationEntity";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface IDepartmentOperation {
  getDepartmentByAsync(
    corpId: number,
    branchId: number
  ): Promise<DepartmentResult>;
}

export class DepartmentOperation implements IDepartmentOperation {
  // Get Corpration detail by Id..
  async getDepartmentByAsync(
    corpId: number,
    branchId: number
  ): Promise<DepartmentResult> {
    const response = await myFetch<DepartmentResult>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_BRANCH_DEPARTMENT,
        corpId,
        branchId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
}
