import React from "react";
import moment from "moment";
import "../dashboard/dayview.css";

interface Event {
  title: string;
  start: moment.Moment;
  end: moment.Moment;
}

const events: Event[] = [
  {
    title: "Meeting with Client",
    start: moment().set({ hour: 9, minute: 0 }),
    end: moment().set({ hour: 10, minute: 30 }),
  },
  {
    title: "Meeting with Client2",
    start: moment().set({ hour: 9, minute: 0 }),
    end: moment().set({ hour: 10, minute: 30 }),
  },
  {
    title: "Lunch Break",
    start: moment().set({ hour: 12, minute: 0 }),
    end: moment().set({ hour: 13, minute: 0 }),
  },
  // Add more events as needed
];

const pixelsPerMinute = 1.4; // Adjust this value according to your layout

const DayView: React.FC = () => {
  const minHour = Math.min(...events.map((event) => event.start.hour()));
  const maxHour = Math.max(...events.map((event) => event.end.hour()));
  const hourHeight = 100; // Set the height of each hour in pixels

  const hourRange = Array.from(
    { length: maxHour - minHour + 1 },
    (_, index) => minHour + index
  );

  // Calculate the height of each minute in pixels
  const minuteHeight = hourHeight / 60;

  return (
    <div className="day-view-container">
      <div className="header">
        <h1>{moment().format("dddd, MMMM D")}</h1>
      </div>
      <div className="day-view-content">
        <div className="hour-column">
          {hourRange.map((hour) => {
            const time = moment().set({ hour, minute: 0 });
            return (
              <div
                key={time.format("HH:mm")}
                className="hour"
                style={{
                  height: `${hourHeight}px`,
                }}
              >
                {time.format("h A")}
              </div>
            );
          })}
        </div>
        <div className="event-column">
          {events.map((event, index) => {
            const startInMinutes = event.start.diff(
              moment().set({ hour: minHour, minute: 0 }),
              "minutes"
            );
            const eventHeightInPixels =
              event.end.diff(event.start, "minutes") * pixelsPerMinute;
            const marginTop = startInMinutes * minuteHeight;

            return (
              <div
                key={index}
                className="event-card"
                style={{
                  marginTop: `${marginTop}px`,
                  height: `${eventHeightInPixels}px`,
                }}
              >
                <strong>{event.title}</strong>
                <div className="event-time">
                  {event.start.format("h:mm A")} - {event.end.format("h:mm A")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DayView;
