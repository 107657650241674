import { Menu, Tabs } from "antd";
import Notification from "./notification";
import MyDetails from "./profileLayout";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { useContext, useEffect, useState } from "react";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import Team from "./team";
import { EmployeeContext } from "../../contexts/employee-context";
import OrgSubscription from "./Org/orgSubscription";

const Settings = () => {
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { employeeData } = useContext(EmployeeContext);

  const defaultPanes = [
    {
      label: "My Details",
      key: "myDetails",
      //children: <MyDetails />,
    },
    {
      label: "Notification",
      key: "notification",
      //children: <Notification />,
    },
  ];

  const [items, setItems] = useState<any>(defaultPanes);

  useEffect(() => {
    if (employeeData?.permissions.find((x) => x === 1)) {
      if (!items.find((y: any) => y.key === "Team")) {
        setItems([
          ...items,
          {
            label: `Team`,
            key: `Team`,
            //children: <Team />,
          },
        ]);
      }
    }
  }, [selectedCompany, userInfo, employeeData]);

  const defaultSelectedKey = "myDetails";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);

  const handleTabChange = (event: any) => {
    setCurrentTab(event.key);
  };

  const renderComponent = () => {
    switch (currentTab) {
      case "myDetails":
        return <MyDetails />;
      case "notification":
        return <Notification />;
      case "Team":
        return <Team />;
      default:
        return <MyDetails />;
    }
  };

  return (
    <PageContainer
      /*extra={[
        <Button key="3">Operate</Button>,
        <Button key="2">Operate</Button>,
        <Button key="1" type="primary">
          Main Operation
        </Button>,
      ]}*/
      subTitle="Settings to configure your basic details, and notifications."
      /*footer={[
      <Button key="3">Reset</Button>,
      <Button key="2" type="primary">
        Submit
      </Button>,
    ]}*/
    >
      <Menu
        onClick={handleTabChange}
        mode="horizontal"
        defaultSelectedKeys={[defaultSelectedKey]}
        selectedKeys={[currentTab]}
        //defaultActiveKey="myDetails"
        //type="card"
        //size="small"
        items={items}
      />
      <br></br>

      {renderComponent()}
    </PageContainer>
  );
};

export default Settings;
