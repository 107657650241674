import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { Button, message, Table, Typography } from "antd";
import { TimeOffReasonType } from "./orgTimeOffReasons-types";
import { ColumnsType } from "antd/es/table";
import { TimeOffOperation } from "../../../serviceoperation/TimeoffOperation";
import { PageContainer } from "@ant-design/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import AddTimeOffReasonForm from "../modal/addTimeOffReason";

const OrgTimeOffReasons = () => {
  const [loading, setLoading] = useState(true);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { selectedCompany } = useContext(CompanyContext);
  const [timeOffReasons, setTimeOffReasons] = useState<TimeOffReasonType[]>([]);
  const [OpenAddTimeOff, setOpenAddTimeOff] = useState(false);
  const [refreshReasons, setRefreshReasons] = useState<boolean>(false);

  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onTimeOffCreate = (values: any) => {
    //console.log("Received values of form: ", values);
    setOpenAddTimeOff(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    setRefreshReasons(!refreshReasons);

    //Show Alert successfully inserted schedule???
    message.success("Successfully created timeoff reason!");
  };

  const isSmallScreen = windowWidth <= 576;

  useEffect(() => {
    const timeOffOps = new TimeOffOperation();
    timeOffOps
      .getTimeOffReasonAsync(selectedCompany?.corporationId as number)
      .then((value) => {
        setTimeOffReasons(
          value!.map((s) => ({
            key: s.timeOffReasonId,
            name: s.name,
            description: s.description,
            createdDateTime: s.createdDateTime,
            status: s.status,
          }))
        );
      })
      .then(() => setLoading(false));
  }, [userInfo, selectedCompany, refreshReasons]);

  const columns: ColumnsType<TimeOffReasonType> = [
    {
      title: "Id",
      dataIndex: "key",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
      responsive: ["lg"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "10%",
      responsive: ["lg"],
    },
    {
      title: "Created Date",
      dataIndex: "createdDateTime",
      width: "10%",
      responsive: ["lg"],
      render: (createdDateTime) => {
        // Convert createdDateTime to a Date object
        const date = new Date(createdDateTime);

        // Get the month abbreviation
        const monthAbbreviation = date.toLocaleString("default", {
          month: "short",
        });

        // Format the date as "Mon DD, YYYY HH:mm:ss"
        const formattedDate = `${monthAbbreviation} ${date.getDate()}, ${date.getFullYear()} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
        return formattedDate;
      },
    },
    {
      title: "Action",
    },
  ];

  return (
    <PageContainer
      style={{ marginLeft: "-16px", marginRight: "-16px" }}
      breadcrumbRender={false}
      title="Time Off Reasons"
      extra={[
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setOpenAddTimeOff(true);
          }}
        >
          Add Time Off Reasons
        </Button>,
      ]}
    >
      <Table
        style={{ whiteSpace: "pre", cursor: "pointer" }}
        columns={columns}
        pagination={false}
        dataSource={timeOffReasons}
        loading={loading}
      />
      <AddTimeOffReasonForm
        open={OpenAddTimeOff}
        onCreate={onTimeOffCreate}
        onCancel={() => {
          setOpenAddTimeOff(false);
        }}
      />
    </PageContainer>
  );
};

export default OrgTimeOffReasons;
