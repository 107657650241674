import { ProCard, StatisticCard } from "@ant-design/pro-card";
import { Divider, Statistic } from "antd";
import { useState } from "react";
import RcResizeObserver from "rc-resize-observer";

const Company = (): JSX.Element => {
  const [current, setCurrent] = useState<string>("dashboard");

  const [responsive, setResponsive] = useState(false);
  const imgStyle = {
    display: "block",
    width: 42,
    height: 42,
  };

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "column" : "row"}
        style={{ marginTop: 24 }}
      >
        <StatisticCard
          statistic={{
            title: "Forcasted Hours",
            value: 2176,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*dr_0RKvVzVwAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
        <StatisticCard
          statistic={{
            title: "Hours Worked",
            value: 475,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*-jVKQJgA1UgAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
        <StatisticCard
          statistic={{
            title: "Days Worked",
            value: 87,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*FPlYQoTNlBEAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
        <StatisticCard
          statistic={{
            title: "浏览量",
            value: 1754,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*pUkAQpefcx8AAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
      </StatisticCard.Group>

      <StatisticCard.Group
        direction={responsive ? "column" : "row"}
        style={{ marginTop: 24 }}
      >
        <StatisticCard
          statistic={{
            title: "总流量(人次)",
            value: 601986875,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "付费流量",
            value: 3701928,
            description: <Statistic title="占比" value="61.5%" />,
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="百分比"
              width="100%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            title: "免费流量",
            value: 1806062,
            description: <Statistic title="占比" value="38.5%" />,
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="百分比"
              width="100%"
            />
          }
          chartPlacement="left"
        />
      </StatisticCard.Group>

      <ProCard
        split={responsive ? "horizontal" : "vertical"}
        headerBordered
        bordered
      >
        <ProCard split="horizontal">
          <ProCard split="horizontal">
            <ProCard split="vertical">
              <StatisticCard
                statistic={{
                  title: "昨日全部流量",
                  value: 234,
                  description: (
                    <Statistic title="较本月平均流量" value="8.04%" />
                  ),
                }}
              />
              <StatisticCard
                statistic={{
                  title: "本月累计流量",
                  value: 234,
                  description: <Statistic title="月同比" value="8.04%" />,
                }}
              />
            </ProCard>
            <ProCard split="vertical">
              <StatisticCard
                statistic={{
                  title: "运行中实验",
                  value: "12/56",
                  suffix: "个",
                }}
              />
              <StatisticCard
                statistic={{
                  title: "历史实验总数",
                  value: "134",
                  suffix: "个",
                  trend: "up",
                }}
              />
            </ProCard>
          </ProCard>
          <StatisticCard
            title="流量走势"
            chart={
              <img
                src="https://gw.alipayobjects.com/zos/alicdn/_dZIob2NB/zhuzhuangtu.svg"
                width="100%"
              />
            }
          />
        </ProCard>
        <StatisticCard
          title="流量走势"
          statistic={{ trend: "up" }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/_dZIob2NB/zhuzhuangtu.svg"
              width="100%"
            />
          }
        />
      </ProCard>
    </RcResizeObserver>
  );
};

export default Company;
