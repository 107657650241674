import { Card, Col, Divider, Row, Space, Switch, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import { BranchesType } from "./orgLocations-types";
import Table, { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { BranchOperation } from "../../../serviceoperation/BranchOperation";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { ConvertTo12hr, AmPMFormat } from "../../../Util/dateutil";

const OrgLocations = () => {
  const [loading, setLoading] = useState(true);
  const { Text, Paragraph } = Typography;
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { selectedCompany } = useContext(CompanyContext);
  const [branches, setBranches] = useState<BranchesType[]>([]);
  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isSmallScreen = windowWidth <= 576;

  useEffect(() => {
    const branchOps = new BranchOperation();
    branchOps
      .getBranchAsync(selectedCompany?.corporationId as number)
      .then((value) => {
        setBranches(
          value!.data!.map((s) => ({
            key: s.branchID,
            divisionId: s.divisionID,
            branchCode: s.branchCode,
            name: s.name,
            branchAddress: {
              addressId: s.branchAddress.addressId,
              line1: s.branchAddress.line1,
              line2: s.branchAddress.line2,
              line3: s.branchAddress.line2,
              city: s.branchAddress.city,
              state: s.branchAddress.state,
              zip: s.branchAddress.zip,
              country: "",
            },
            createdDateTime: s.createdDateUTC,
            status: s.isActive,
          }))
        );
      })
      .then(() => setLoading(false));
  }, [userInfo]);

  const columns: ColumnsType<BranchesType> = [
    {
      title: "Id",
      dataIndex: "key",
      width: "5%",
    },
    {
      title: "Branch Code",
      dataIndex: "branchCode",
      width: "10%",
      responsive: ["sm"],
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
      responsive: ["lg"],
    },
    {
      title: "Address",
      dataIndex: "branchAddress",
      render: (_, record) => (
        <div>
          <div>
            {record.branchAddress.line1}
            {""}
            {record.branchAddress.line2} {record.branchAddress.line3}
            <br />
            {record.branchAddress.city}
            {","}
            {record.branchAddress.state} {record.branchAddress.zip}
          </div>
        </div>
      ),
      responsive: ["sm"],
      width: isSmallScreen ? "50%" : "20%",
    },
    {
      title: "Requested On",
      dataIndex: "createdDateTime",
      width: "10%",
      responsive: ["lg"],
      render: (createdDateTime) => {
        // Convert createdDateTime to a Date object
        const date = new Date(createdDateTime);

        // Get the month abbreviation
        const monthAbbreviation = date.toLocaleString("default", {
          month: "short",
        });

        // Format the date as "Mon DD, YYYY HH:mm:ss"
        const formattedDate = `${monthAbbreviation} ${date.getDate()}, ${date.getFullYear()} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
        return formattedDate;
      },
    },
  ];

  return (
    <div style={{ margin: "16px" }}>
      <Table
        style={{ whiteSpace: "pre", cursor: "pointer" }}
        columns={columns}
        pagination={false}
        dataSource={branches}
        onRow={(record) => {
          return {
            //onClick: () => handleRowClick(record),
          };
        }}
        loading={loading}
      />
    </div>
  );
};

export default OrgLocations;
