import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { ScheduleDetails } from "../../serviceoperation/entity/ScheduleOperationEntity";
import { PlusOutlined } from "@ant-design/icons";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import { CompanyContext } from "../../contexts/company-context";

interface Item {
  key: number;
  activityType: string;
  activityDateTime: any;
  newRow: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "date" | "dropdown";
  record: Item;
  index: number;
  children: React.ReactNode;
}

interface ModifiyFormProps {
  scheduleDetail: ScheduleDetails;
  open: boolean;
  onCreate: () => void;
  onCancel: () => void;
}

const ScheduleActivityModify: React.FC<ModifiyFormProps> = ({
  scheduleDetail,
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { selectedCompany } = useContext(CompanyContext);
  const [data, setData] = useState<Item[]>([]);
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState<number>(-1);
  const [activityTypes, setActivityTypes] = useState([
    {
      value: "CLOCK_IN",
      label: "CLOCK_IN",
    },
    {
      value: "BREAK_START",
      label: "BREAK_START",
    },
    {
      value: "BREAK_END",
      label: "BREAK_END",
    },
    {
      value: "CLOCK_OUT",
      label: "CLOCK_OUT",
    },
  ]);
  const isEditing = (record: Item) => record.key === editingKey;

  // Compare datetime values and throw validation error if necessary
  const validateDatetime =
    (index: number) => (rule: any, value: Date | null) => {
      if (data && value) {
        const dataIndex = data.findIndex((x) => x.key === index);

        if (dataIndex !== -1) {
          const upperBoundData = data[dataIndex - 1];
          const lowerBoundData = data[dataIndex + 1];

          if (
            (!upperBoundData || upperBoundData.activityDateTime < value) &&
            (!lowerBoundData || lowerBoundData.activityDateTime > value)
          ) {
            return Promise.resolve();
          }
        }
      }
      return Promise.reject(
        "Ensure that the date and time are greater than the previous row and less than the subsequent row."
      );
    };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : inputType === "date" ? (
        <DatePicker
          //defaultValue={record.activityDateTime}
          value={record.activityDateTime}
          format="YYYY-MM-DD h:mm a"
          showTime
          size="small"
          style={{ width: "100%" }}
          key={record.key}
        />
      ) : inputType === "dropdown" ? (
        <Select
          disabled={!record.newRow}
          placeholder="Select Activity"
          key={record.key}
          value={record.activityType}
          options={activityTypes}
        />
      ) : (
        <Input />
      );
    return (
      <td {...restProps}>
        {editing && inputType !== "date" ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : editing && inputType === "date" ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                validator: validateDatetime(record.key),
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  useEffect(() => {
    setData(
      scheduleDetail?.shiftActivity.map((item) => ({
        key: item.scheduleActivityID,
        activityType: item.activityType,
        activityDateTime: dayjs(item.activityDateTime + "Z"),
        newRow: false,
      }))
    );
  }, []);

  useEffect(() => {
    if (data) {
      let lastItem = data[data.length - 1];

      if (lastItem?.newRow === true) {
        lastItem = data[data.length - 2];
      }

      const activityTypeLookup: Record<
        string,
        { value: string; label: string }[]
      > = {
        CLOCK_IN: [
          { value: "BREAK_START", label: "BREAK_START" },
          { value: "BREAK_END", label: "BREAK_END" },
          { value: "CLOCK_OUT", label: "CLOCK_OUT" },
        ],
        CLOCK_OUT: [],
        BREAK_START: [{ value: "BREAK_END", label: "BREAK_END" }],
        BREAK_END: [
          { value: "BREAK_START", label: "BREAK_START" },
          { value: "CLOCK_OUT", label: "CLOCK_OUT" },
        ],
      };

      const lastActivityType = lastItem?.activityType || "default";
      const activityTypes = activityTypeLookup[lastActivityType] || [
        { value: "CLOCK_IN", label: "CLOCK_IN" },
      ];
      const disableAdd = lastActivityType === "CLOCK_OUT";

      setActivityTypes(() => activityTypes);
      setDisableAdd(() => disableAdd);
    }
  }, [data]);

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
    setDisableAdd(true);
  };

  const cancel = (value?: Item) => {
    setEditingKey(-1);
    if (value?.newRow) {
      const updatedData = [...data].filter((item) => item.key !== value.key);
      setData(updatedData);
    }
    setDisableAdd(false);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      const item = newData[index];

      const scheduleOps = new ScheduleOperation();
      await scheduleOps
        .patchScheduleActivityAsync(
          selectedCompany?.corporationId as number,
          scheduleDetail.scheduleID,
          [
            {
              path: "/",
              op: item.newRow === false ? "replace" : "add",
              value: {
                ActivityId: key as number,
                ActivityType: row.activityType,
                DateTimeUTC: new Date(row.activityDateTime).toISOString(),
              },
            },
          ]
        )
        .then((x) => {
          if (x.statusCode === 200) {
            message.success("Successfully processed your request!");
            // On the schedule Cancel, navigate to Schedule Page.

            if (index > -1) {
              //const item = newData[index];
              // Set Row to not new data...
              item.newRow = false;
              newData.splice(index, 1, {
                ...item,
                ...row,
              });
              setData(newData);
              setEditingKey(-1);
            } else {
              newData.push(row);
              setData(newData);
              setEditingKey(-1);
            }
            setDisableAdd(false);
          } else {
            message.error("There was an error trying to process your request!");
          }
        })
        .catch((x) => {
          message.error("There was an error trying to process your request!");
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = () => {
    const newData = {
      key: Date.now(),
      activityType: "",
      activityDateTime: "",
      newRow: true,
    };
    const updatedData = [...data, newData];
    setData(updatedData);
    edit(newData);
  };

  const columns = [
    {
      title: "Activity ID",
      dataIndex: "key",
      width: "12%",
      editable: false,
    },
    {
      title: "Activity Type",
      dataIndex: "activityType",
      width: "20%",
      editable: true,
    },
    {
      title: "Date Time",
      dataIndex: "activityDateTime",
      width: "40%",
      editable: true,
      render: (_: any, record: Item) =>
        new Date(record.activityDateTime).toLocaleTimeString([], {
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Are you sure?" onConfirm={() => cancel(record)}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== -1}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType:
          col.dataIndex === "activityDateTime"
            ? "date"
            : col.dataIndex === "activityType"
            ? "dropdown"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Modal
      open={open}
      title="Modify Schedule Activity"
      okText="Done"
      cancelText="Cancel"
      onCancel={onCancel}
      footer={[
        <Button
          key="okay"
          type="primary"
          onClick={() => {
            onCreate();
          }}
        >
          Done
        </Button>,
      ]}
      width={1000}
    >
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: () => cancel(),
          }}
        />
        <Button
          type="primary"
          onClick={handleAdd}
          disabled={disableAdd}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <PlusOutlined /> Add Row
        </Button>
      </Form>
    </Modal>
  );
};

export default ScheduleActivityModify;
