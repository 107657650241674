// darkModeContext.tsx

import React, { ReactNode, useMemo, useState } from "react";
import { DarkModeContext, DarkModeContextType } from "./theme-context";

interface ThemeContextProviderProps {
  children: ReactNode;
}

export const DarkModeProvider = (props: ThemeContextProviderProps) => {
  const { children } = props;

  const [darkMode, setDarkMode] = useState<boolean>(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const darkModeContextValue: DarkModeContextType = {
    darkMode,
    toggleDarkMode,
  };

  /*const values: DarkModeContextType = useMemo(
    () => ({ darkMode, toggleDarkMode }),
    [darkMode]
  );*/

  return (
    <DarkModeContext.Provider value={darkModeContextValue}>
      {children}
    </DarkModeContext.Provider>
  );
};
