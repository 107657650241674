function StringFormat(str: string, ...args: any[]) {
  return str.replace(/{(\d+)}/g, (match, index) => args[index] || "");
}

const getInitials = (string: string): string => {
  if (!string) {
    return "";
  }

  const names: string[] = string.split(" ");
  let initials: string = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};
export { StringFormat, getInitials };
