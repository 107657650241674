import { Form, Modal, Input, Alert } from "antd";
import { useContext, useState } from "react";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { TimeOffOperation } from "../../../serviceoperation/TimeoffOperation";

interface Values {
  name: string;
  description: string;
}

interface AddTimeOffReasonFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

//AddScheduleForm
const AddTimeOffReasonForm: React.FC<AddTimeOffReasonFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { selectedCompany } = useContext(CompanyContext);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userInfo = useContext(AuthenticationContext)?.userInfo;

  const addTimeOffReasons = async (values: Values): Promise<boolean> => {
    setConfirmLoading(true);
    let success = false;

    const timeOffOps = new TimeOffOperation();

    await timeOffOps
      .postTimeOffReasonAsync(selectedCompany?.corporationId as number, {
        name: values.name,
        description: values.description,
      })
      .then((x) => {
        success =
          x.statusCode != undefined && x.statusCode.toString() === "201";

        // Set Error Message for Conflict.
        if (x.statusCode != undefined && x.statusCode.toString() === "409") {
          setShowError(true);
          setErrorMessage("TimeOff reason already exist");
        }
      });
    setConfirmLoading(false);
    return success;
  };

  return (
    <Modal
      open={open}
      title="Add TimeOff Reason"
      okText="Add"
      cancelText="Cancel"
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      onOk={() => {
        setConfirmLoading(true);
        form
          .validateFields()
          .then(async (values) => {
            // Insert / Invite Employee
            // to join the company.
            if (await addTimeOffReasons(values)) {
              form.resetFields();
              onCreate(values);
            } else {
              setShowError(true);
              // Stay on the Page and keep the Modal open and display Error message.
            }
          })
          .catch((info) => {
            setShowError(true);
            setConfirmLoading(false);
          });
        setConfirmLoading(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item name="name" label="Name">
          <Input placeholder="TimeOff Reason" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea showCount maxLength={255} />
        </Form.Item>
      </Form>
      {showError ? (
        <Alert
          message={
            errorMessage && errorMessage !== ""
              ? errorMessage
              : "There was an error processing your request. Please try again later."
          }
          type="error"
          showIcon
        />
      ) : null}
    </Modal>
  );
};

export default AddTimeOffReasonForm;
