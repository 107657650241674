import { GenericStatus, PagedResult, Status } from "./entity/Status";
import { StringFormat } from "../Util/stringutil";
import {
  GetScheduleReplacement,
  ResponseFindReplacement,
  ResponseInsertReplacement,
} from "./entity/ScheduleReplacementOperationEntity";
import { constants } from "../constants";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface IScheduleReplacementOperation {
  getFindScheduleReplacementAsync(
    corpId: number,
    scheduleId: number
  ): Promise<Status<ResponseFindReplacement[]>>;

  postScheduleReplacementAsync(
    corpId: number,
    scheduleId: number,
    replacementScheduleId: number
  ): Promise<Status<ResponseInsertReplacement>>;

  getScheduleReplacementAsync(
    corpId: number,
    page: number,
    pageSize: number
  ): Promise<Status<PagedResult<GetScheduleReplacement[]>>>;

  postScheduleReplacementStatusAsync(
    corpId: number,
    replacementId: string,
    approve: boolean
  ): Promise<GenericStatus>;
}

export class ScheduleReplacementOperation
  implements IScheduleReplacementOperation
{
  async postScheduleReplacementStatusAsync(
    corpId: number,
    replacementId: string,
    approve: boolean
  ): Promise<GenericStatus> {
    const response = await myFetch<GenericStatus>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_SCHEDULE_REPLACEMENT_STATUS,
        corpId,
        replacementId,
        approve ? "Approve" : "Decline"
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer  Dummy`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async postScheduleReplacementAsync(
    corpId: number,
    scheduleId: number,
    replacementScheduleId: number
  ): Promise<Status<ResponseInsertReplacement>> {
    const response = await myFetch<Status<ResponseInsertReplacement>>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_SCHEDULE_REPLACEMENT,
        corpId,
        scheduleId,
        replacementScheduleId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer  Dummy`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
  async getFindScheduleReplacementAsync(
    corpId: number,
    scheduleId: number
  ): Promise<Status<ResponseFindReplacement[]>> {
    const response = await myFetch<Status<ResponseFindReplacement[]>>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_REPLACEMENT,
        corpId,
        scheduleId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer  Dummy`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async getScheduleReplacementAsync(
    corpId: number,
    page: number,
    pageSize: number
  ): Promise<Status<PagedResult<GetScheduleReplacement[]>>> {
    const response = await myFetch<
      Status<PagedResult<GetScheduleReplacement[]>>
    >(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_REPLACEMENT_NOTI,
        corpId
      )}?page=${page}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer  Dummy`,
        }),
      }
    );
    const data = await response.json();
    return data;
  }
}
