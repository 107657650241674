import { Steps, message } from "antd";
import { useCallback, useState } from "react";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { useNavigate } from "react-router";
import AddCorpInfoForm from "./corp-info";
import AddCorpMetadataForm from "./corp-metadata";
import AddCorpBranchForm from "./corp-branch";
import { NewCorpEntity } from "./corp-new-entity";
import CorpSummary from "./corp-summary";
import { ReactComponent as IconWhite } from "../../images/Icon_White.svg";
import StripePricingPage from "./corp-pricing-table";
import { pricingTable } from "../../constants";

interface NewCorpLayoutFormProps {
  onCreate?: (values: NewCorpEntity) => void;
}

const NewCorporationLayout: React.FC<NewCorpLayoutFormProps> = () => {
  const defaultSelectedKey = 0;
  const [currentStep, setCurrentStep] = useState<number>(defaultSelectedKey);
  const [newCorpData, setNewCorpData] = useState<NewCorpEntity>({});
  const [clientRefId, setClientRefId] = useState<string>();

  const navigate = useNavigate();

  const stepLength = 6;

  const saveNewCorpData = useCallback(
    (data: any) => {
      setNewCorpData(data);
      console.log(data);
      next();
      //setScheduleData(data);
      //SetEmployee(employeeData);
      //setEmployeeDetails(employeeDetails);
      //setSelectedWeek(selectedWeek);
      //setNextDisabled(data.length > 0 ? false : true);
    },
    [currentStep]
  );

  const looksGood = (values: NewCorpEntity, response: any) => {
    console.log(response.data.corporationID);
    setClientRefId(response.data.corporationID);
    // Set the next step so that we can to sbscription page.
    next();
    //window.location.href = "/";
  };

  const handleStepChange = (value: number) => {
    setCurrentStep(value);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const submitSchedule = () => {
    message.success("Schedule successfully published!");
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <AddCorpInfoForm
            newCorpData={newCorpData}
            onCreate={saveNewCorpData}
          />
        );
      case 1:
        return (
          <AddCorpMetadataForm
            newCorpData={newCorpData}
            onCreate={saveNewCorpData}
          />
        );
      /*case 2:
        return (
          <PricingPage newCorpData={newCorpData} onCreate={saveNewCorpData} />
        );*/
      case 2:
        return (
          <AddCorpBranchForm
            newCorpData={newCorpData}
            onCreate={saveNewCorpData}
          />
        );
      /*case 4:
        return (
          <AddTimeOffReasons
            newCorpData={newCorpData}
            onCreate={saveNewCorpData}
          />
        );*/
      case 3:
        return <CorpSummary newCorpData={newCorpData} onCreate={looksGood} />;
      case 4:
        return (
          <StripePricingPage
            pricingTableId={pricingTable.Id}
            publishableKey={pricingTable.publishableKey}
            referenceId={clientRefId!}
          />
        );
    }
  };

  return (
    <PageContainer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconWhite width={32} height={32} />
          myTimeverse
        </div>
      }
      subTitle="Effortless Employee Scheduling for Small and Large Businesses!"
      footer={[
        <p>© {new Date().getFullYear()} myTimeverse. All rights reserved.</p>,
      ]}
    >
      <ProCard>
        <Steps
          type="default"
          size="small"
          current={currentStep}
          onChange={handleStepChange}
          className="site-navigation-steps"
          items={[
            {
              title: "Step 1",
              subTitle: "Account Details",
              description: "Tell us little about you and your corporation.",
              disabled: true,
            },
            {
              title: "Step 2",
              subTitle: "Settings",
              description: "Misc settings",
              disabled: true,
            },
            /*{
              title: "Step 3",
              subTitle: "Plan Selection",
              description: "Selct a plan of your choice!",
              disabled: true,
            },*/
            {
              title: "Step 3",
              subTitle: "Branch and Department",
              description: "Configuration of your locations.",
              disabled: true,
            },
            /*{
              title: "Step 5",
              subTitle: "Time Off",
              description: "Time off configuration.",
              disabled: true,
            },*/
            {
              title: "Step 4",
              subTitle: "Summary",
              description: "You're almost there.",
              disabled: true,
            },
          ]}
        />
        {renderComponent()}
      </ProCard>
    </PageContainer>
  );
};

export default NewCorporationLayout;
