import { constants } from "../constants";
import { StringFormat } from "../Util/stringutil";
import { ReadBranchDetails } from "./entity/BranchOperationEntity";
import { Preference } from "./entity/MetadataOperationEntity";
import { Status } from "./entity/Status";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface IMetadataOperation {
  getMetadataAsync(prefName: string): Promise<Status<Preference[]>>;
}

export class MetadataOperation implements IMetadataOperation {
  // Get Corpration detail by Id..
  async getMetadataAsync(prefName: string): Promise<Status<Preference[]>> {
    const response = await myFetch<Status<Preference[]>>(
      `${constants.BASE_URL}${StringFormat(constants.GET_METADATA, prefName)}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
}
