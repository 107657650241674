import {
  CheckOutlined,
  CloseOutlined,
  ExpandAltOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Result,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useRef, useState } from "react";
import { timeOffStatus } from "../../../constants";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { TimeOffOperation } from "../../../serviceoperation/TimeoffOperation";
import { formatDate } from "../../../Util/dateutil";
import {
  TimeOffRequestPagination,
  TimeOffRequestsType,
} from "../time-off-types";
import { PageContainer } from "@ant-design/pro-layout";
import ProCard from "@ant-design/pro-card";

const TimeOffManager = (): JSX.Element => {
  // Contexts.
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { Title, Paragraph, Text } = Typography;
  const [expand, setExpand] = useState(false);
  const timeOffOps = new TimeOffOperation();

  const [timeOffPagination, SetTimeOffPagination] =
    useState<TimeOffRequestPagination>();

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleTimeoffAction = async (
    index: number,
    timeOffId: string,
    status: string
  ) => {
    await timeOffOps
      .postScheduleTimeOffApprovalAsync(
        selectedCompany?.corporationId as number,
        timeOffId,
        status
      )
      .then((value) => {
        if (value.statusCode === 200) {
          message.success("Successfully processed your request.");

          // This should never be null so just taking the first
          // item from the list.
          const processedTimeOff = timePendingOff.filter(
            (e, i) => e.key === timeOffId
          )[0];

          // On a successful request, remove the item from the list
          // No reason to refetch the API for updated list.
          SetPendingTimeOff(
            timePendingOff.filter((e, i) => e.key != timeOffId)
          );

          // Pop the Object into SetEmployee...
          SetEmployeeTimeOff([...employeeTimeOff, processedTimeOff]);
        } else {
          message.error(
            "An error has occurred while processing your request, please try again!"
          );
        }
      })
      .catch((e) => {
        message.error(
          "An error has occurred while processing your request, please try again!"
        );
      });
  };
  const [timePendingOff, SetPendingTimeOff] = useState<TimeOffRequestsType[]>(
    []
  );
  const [employeeTimeOff, SetEmployeeTimeOff] = useState<TimeOffRequestsType[]>(
    []
  );

  const columns: ColumnsType<TimeOffRequestsType> = [
    {
      title: "Leave Type",
      dataIndex: "type",
      width: "5%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "5%",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "25%",
      render: (text) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Requested On",
      dataIndex: "requestedOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            color={
              status === "Requested"
                ? "geekblue"
                : status === "Approved"
                ? "green"
                : "volcano"
            }
          >
            {status}{" "}
          </Tag>
        </>
      ),
      width: "10%",
    },
    {
      title: "Action",
      render: (_, record, index) => (
        <>
          <Space size="middle">
            {record.status === "Requested" ? (
              <Button
                type="primary"
                ghost
                color="green"
                icon={<CheckOutlined />}
                size={"small"}
                onClick={() =>
                  handleTimeoffAction(
                    index,
                    record.key.toString(),
                    timeOffStatus.APPROVE
                  )
                }
                style={{ borderColor: "green", color: "green" }}
              >
                Approve
              </Button>
            ) : null}
            {record.status === "Requested" ? (
              <Button
                type="default"
                danger
                icon={<CloseOutlined />}
                size={"small"}
                onClick={() =>
                  handleTimeoffAction(
                    index,
                    record.key.toString(),
                    timeOffStatus.DENY
                  )
                }
              >
                Deny
              </Button>
            ) : null}
          </Space>
        </>
      ),
      width: "10%",
    },
  ];
  const employeeColumns: ColumnsType<TimeOffRequestsType> = [
    {
      title: "Leave Type",
      dataIndex: "type",
      width: "5%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "5%",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "25%",
      render: (text) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Requested On",
      dataIndex: "requestedOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            color={
              status === "Requested"
                ? "geekblue"
                : status === "Approved"
                ? "green"
                : "volcano"
            }
          >
            {status}{" "}
          </Tag>
        </>
      ),
      width: "10%",
    },
  ];

  useEffect(() => {
    const employeeId = userInfo?.employeeCorporation
      ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
      .map((x) => x.employeeId)
      .at(0);

    timeOffOps
      .getScheduleTimeOffAsync(selectedCompany?.corporationId as number, {
        includeDirectReport: true,
        page: 1,
        pageSize: 100,
      })
      .then((value) => {
        /*SetTimeOffPagination({
          currentPage: value.currentPage,
          pageCount: value.pageCount,
          pageSize: value.pageSize,
          rowCount: value.rowCount,
        });*/

        //SetPendingTimeOff Approval request.
        SetPendingTimeOff(
          value.results
            .filter((x) => x.timeOffStatus === "Requested" && x.canApprove)
            .map((s) => ({
              key: s.timeOffId,
              type: s.timeOffReasonName,
              duration: s.duration,
              startDate: formatDate(s.startDate),
              endDate: formatDate(s.endDate),
              name: `${s.employee.firstName} ${s.employee.lastName}`,
              title: s.note,
              requestedOn: new Date(s.createdDateTime + "Z").toDateString(),
              status: s.timeOffStatus,
            }))
        );

        // Set myTime Off Requests.
        // This will insure that right data is being pulled for a manager.
        SetEmployeeTimeOff(
          value.results
            .filter(
              (x) =>
                x.timeOffStatus != "Requested" &&
                x.employee.employeeId != employeeId
            )
            .map((s) => ({
              key: s.timeOffId,
              type: s.timeOffReasonName,
              duration: s.duration,
              startDate: formatDate(s.startDate),
              endDate: formatDate(s.endDate),
              name: `${s.employee.firstName} ${s.employee.lastName}`,
              title: s.note,
              requestedOn: new Date(s.createdDateTime + "Z").toDateString(),
              status: s.timeOffStatus,
            }))
        );
      });
  }, [userInfo, selectedCompany]);

  return (
    <PageContainer
      title="Manage"
      subTitle="View and manage employees time off requests!"
      breadcrumb={{
        routes: [
          { path: "/schedule/home", breadcrumbName: "Schedule" },
          { path: "/schedule/timeoff", breadcrumbName: "Time Off" },
          { path: "/schedule/timeoff/manager", breadcrumbName: "Manage" },
        ],
      }}
    >
      <ProCard style={{ marginTop: 24 }}>
        <Title level={4}>
          {<ExpandAltOutlined onClick={handleExpand} />} Pending Approval(s)
        </Title>
        {expand ? (
          <>
            <Table
              style={{ whiteSpace: "pre" }}
              columns={columns}
              dataSource={timePendingOff}
              locale={{
                emptyText: "No pending approvals",
              }}
            />{" "}
          </>
        ) : null}
      </ProCard>

      <ProCard style={{ marginTop: 24 }}>
        <Title level={4}>Employee's Requests History</Title>
        <Table
          style={{ whiteSpace: "pre", marginTop: 24 }}
          columns={employeeColumns}
          dataSource={employeeTimeOff}
          /*pagination={{
            showSizeChanger: true,
            defaultCurrent: 1,
            defaultPageSize: 5,
            pageSizeOptions: ["5", "10", "25"],
            position: ["bottomRight"],
            size: "default",
            responsive: true,
            total: timeOffPagination?.rowCount ?? 1,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}*/
        />
      </ProCard>
    </PageContainer>
  );
};

export default TimeOffManager;
