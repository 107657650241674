import { constants } from "../constants";
import { InsertActivityStatus } from "./entity/ScheduleOperationEntity";
import { StringFormat } from "../Util/stringutil";
import {
  ResponseTimeOffRequest,
  InsertScheduleTimeOffRequest,
  InsertTimeOffRequest,
  TimeOffReasons,
  TimeOffStatistics,
  InsertTimeOffReasonRequest,
} from "./entity/TimeOffOperationEntity";
import { RequestTimeOffParam } from "../components/schedule/time-off-types";
import { Stat, Status } from "./entity/statusEntity";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface ITimeOffOperation {
  getTimeOffReasonAsync(corpId: number): Promise<TimeOffReasons[]>;

  postTimeOffReasonAsync(
    corpId: number,
    requestBody: InsertTimeOffReasonRequest
  ): Promise<Status>;

  getTimeOffStatAsync(corpId: number): Promise<Stat<TimeOffStatistics>>;
  //GET_TIMEOFF_STAT_ENDPOINT

  postScheduleTimeOffAsync(
    corpId: number,
    scheduleId: number,
    requestBody: InsertScheduleTimeOffRequest
  ): Promise<Status>;

  getScheduleTimeOffAsync(
    corpId: number,
    reqTimeOffPram: RequestTimeOffParam
  ): Promise<ResponseTimeOffRequest>;

  postScheduleTimeOffApprovalAsync(
    corpId: number,
    timeOffId: string,
    statusId: string
  ): Promise<Status>;

  postTimeOffAsync(
    corpId: number,
    employeeId: string,
    requestBody: InsertTimeOffRequest
  ): Promise<Status>;
}

export class TimeOffOperation implements ITimeOffOperation {
  async postTimeOffReasonAsync(
    corpId: number,
    requestBody: InsertTimeOffReasonRequest
  ): Promise<Status> {
    const response = await myFetch<Stat<TimeOffStatistics>>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_TIMEOFF_REASON,
        corpId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(requestBody),
      }
    );

    const data = await response.json();
    return data;
  }

  async getTimeOffStatAsync(corpId: number): Promise<Stat<TimeOffStatistics>> {
    const response = await myFetch<Stat<TimeOffStatistics>>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_TIMEOFF_STAT_ENDPOINT,
        corpId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
  /*accessToken: string;

  constructor(accessToken: string) {
    //TODO: look up an alternative solution for initially null context props
    this.accessToken = accessToken;
  }*/
  async getTimeOffReasonAsync(corpId: number): Promise<TimeOffReasons[]> {
    const response = await myFetch<TimeOffReasons[]>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_TIMEOFF_REASON,
        corpId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
  async postTimeOffAsync(
    corpId: number,
    employeeId: string,
    requestBody: InsertTimeOffRequest
  ): Promise<Status> {
    const response = await myFetch<Status>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_TIMEOFF_ENDPOINT,
        corpId,
        employeeId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(requestBody),
      }
    );
    const data = await response.json();
    return data;
  }
  async postScheduleTimeOffApprovalAsync(
    corpId: number,
    timeOffId: string,
    statusId: string
  ): Promise<Status> {
    const response = await myFetch<Status>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_SCHEDULE_TIMEOFF_STATUS_ENDPOINT,
        corpId,
        timeOffId,
        statusId
      )}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
      }
    );
    const data = await response.json();
    return data;
  }
  async getScheduleTimeOffAsync(
    corpId: number,
    reqTimeOffPram: RequestTimeOffParam
  ): Promise<ResponseTimeOffRequest> {
    const querystring = Object.entries(reqTimeOffPram)
      .map(([key, value]) => key + "=" + value)
      .join("&");
    const response = await myFetch<ResponseTimeOffRequest>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_TIMEOFF_ENDPOINT,
        corpId
      )}?${querystring}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    return {
      results: [],
      currentPage: 1,
      pageCount: 0,
      pageSize: 0,
      rowCount: 0,
      firstRowOnPage: 0,
      lastRowOnPage: 0,
    };
  }

  async postScheduleTimeOffAsync(
    corpId: number,
    scheduleId: number,
    requestBody: InsertScheduleTimeOffRequest
  ): Promise<Status> {
    const response = await myFetch<InsertActivityStatus>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_SCHEDULE_TIMEOFF_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(requestBody),
      }
    );
    const data = await response.json();
    return data;
  }
}
