import {
  Form,
  Input,
  Typography,
  Row,
  Button,
  Col,
  DatePicker,
  Switch,
  InputNumber,
  Space,
  message,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { Metadata } from "../../../serviceoperation/entity/CorporationOperationEntity";
import { Preference } from "../../../serviceoperation/entity/MetadataOperationEntity";
import { MetadataOperation } from "../../../serviceoperation/MetadataOperation";
import { CorporationOperation } from "../../../serviceoperation/CorporationOperation";
import { CompanyContext } from "../../../contexts/company-context";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

interface CorpMetadataFormProps {
  newCorpData: Metadata[];
}

//AddScheduleForm
const CorpMetadataForm: React.FC<CorpMetadataFormProps> = ({ newCorpData }) => {
  const [form] = Form.useForm();
  const [metadata, setMetadata] = useState<Preference[]>();
  const { selectedCompany } = useContext(CompanyContext);
  const [metaValue, setMetaValue] = useState<{ [key: number]: string }>({});
  const [metaValueTemp, setMetaValueTemp] = useState<{ [key: number]: string }>(
    {}
  );

  dayjs.extend(customParseFormat);

  const dateFormat = "YYYY-MM-DD";
  const [editingField, setEditingField] = useState<number>(0);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const patchCorporationSettings = new CorporationOperation();

  useEffect(() => {
    const metadata = new MetadataOperation();
    metadata
      .getMetadataAsync("CORP")
      .then((x) => {
        if (x.statusCode == 200 && x.data) {
          setMetadata(x.data);
        }
      })
      .then(() => {
        const corp = new CorporationOperation();
        corp.getCorpByIdAsync(1).then((corpResult) => {
          if (corpResult.statusCode == 200 && corpResult.data) {
            corpResult.data.preference.forEach((mapCorpPref) => {
              // Compute the key and value
              const { metadataId, metadataValue } = mapCorpPref;

              // Update state using computed key
              setMetaValue((prevState) => ({
                ...prevState,
                [metadataId]: metadataValue,
              }));
            });
            // Cross Reference The Data with the original MetaData List.
          }
        });
      });
  }, []);

  const handleInputChange = (metadataId: number, metadataValue: any) => {
    // Update state and log the updated value
    setMetaValueTemp((prevState) => {
      const updatedState = {
        ...prevState,
        [metadataId]: metadataValue,
      };
      return updatedState;
    });

    // Perform other actions after state update
    setEditingField(metadataId);
    setIsValueChanged(true);
  };

  const handleSaveClick = (metadataId: number) => {
    // Patch Corporation's Preference.....
    patchCorporationSettings
      .patchCorp(selectedCompany?.corporationId as number, [
        {
          operationType: 2,
          op: "Replace",
          value: {
            metadata: [
              {
                metadataID: metadataId,
                metadataValue: metaValueTemp[metadataId],
              },
            ],
          },
        },
      ])
      .then((x) => {
        if (x.statusCode == 200) {
          setMetaValue((prevState) => {
            const updatedState = {
              ...prevState,
              [metadataId]: metaValueTemp[metadataId],
            };
            return updatedState;
          });
          setEditingField(0); // Reset editing field state
        } else {
          message.error("There was an error trying to process your request!");
        }
      })
      .then((x) => {
        message.success("Successfully processed your request.");
      });

    console.log("Temp Value In HandleSaveclick", metaValueTemp[metadataId]);
  };

  const handleCancelClick = (metadataId: number) => {
    setMetaValue((prevState) => {
      const updatedState = {
        ...prevState,
        [metadataId]: metaValue[metadataId] ?? null,
      };
      return updatedState;
    });
    setEditingField(0); // Reset editing field state
  };

  return (
    <Form
      layout="vertical"
      /*onFinish={onFinish}*/ style={{ margin: "40px" }}
      form={form}
      name="metadata"
      initialValues={newCorpData}
    >
      {metadata?.map((field, i) => {
        return (
          <div key={i}>
            <Typography.Title level={5}>
              {field.preferenceName}
            </Typography.Title>
            <Typography.Paragraph>
              {field.preferenceDescription}
            </Typography.Paragraph>

            {field.preferenceMetadata.map((meta, i) => {
              return (
                <Row gutter={[10, 4]} key={meta.metaDataId}>
                  <Col md={14} xs={24}>
                    <Form.Item
                      name={["metadata", `${meta.metaDataId}`]}
                      label={`${meta.metaDescription}`}
                      initialValue={
                        newCorpData.find(
                          (x) => x.metadataId === meta.metaDataId
                        )?.metadataValue
                      }
                      key={i}
                      rules={[
                        {
                          required: true,
                          message:
                            meta.metaDataType === "int"
                              ? `${meta.metaDataName} is required and must be a number`
                              : `${meta.metaDataName} is required.`,
                          pattern:
                            meta.metaDataType === "int"
                              ? new RegExp(/^[0-9]+$/)
                              : new RegExp(/^/),
                        },
                      ]}
                      //htmlFor={`${field.name}`}
                    >
                      <Space.Compact block>
                        {meta.metaDataName.includes("_DATE") ? (
                          <DatePicker
                            id={`${meta.metaDataId}`}
                            defaultValue={
                              dayjs(
                                newCorpData.find(
                                  (x) => x.metadataId === meta.metaDataId
                                )?.metadataValue || null,
                                dateFormat
                              ) || null
                            }
                            onChange={(date) =>
                              handleInputChange(meta.metaDataId, date)
                            }
                          />
                        ) : meta.metaDataType === "bool" ? (
                          <Switch
                            id={`${meta.metaDataId}`}
                            defaultChecked={
                              newCorpData.find(
                                (x) => x.metadataId === meta.metaDataId
                              )?.metadataValue === "true"
                            }
                            onChange={(checked) =>
                              handleInputChange(meta.metaDataId, checked)
                            }
                          />
                        ) : meta.metaDataType === "int" ? (
                          <InputNumber
                            style={{ width: "100%" }}
                            id={`${meta.metaDataId}`}
                            value={metaValue[meta.metaDataId]}
                            placeholder={meta.metaDescription}
                            onChange={(value) =>
                              handleInputChange(meta.metaDataId, value)
                            }
                          />
                        ) : (
                          <Input
                            id={`${meta.metaDataId}`}
                            value={metaValue[meta.metaDataId]}
                            placeholder={meta.metaDescription}
                            onChange={(e) =>
                              handleInputChange(meta.metaDataId, e.target.value)
                            }
                          />
                        )}

                        {editingField === meta.metaDataId && (
                          <Space.Compact size="middle" block>
                            <Button
                              type="primary"
                              onClick={() => handleSaveClick(meta.metaDataId)}
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={() => handleCancelClick(meta.metaDataId)}
                              type="ghost"
                            >
                              Cancel
                            </Button>
                          </Space.Compact>
                        )}
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </div>
        );
      })}
    </Form>
  );
};

export default CorpMetadataForm;
