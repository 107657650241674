import { Space, Card, Col, Row, Radio, Typography, Alert, message } from "antd";
import { useContext, useMemo, useState } from "react";
import { CompanyContext } from "../../contexts/company-context";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { AuthenticationContext } from "../../contexts/authentication-context";
import React from "react";
import { Day, EmployeeAvailability } from "./my-availability-types";

const daysOfWeek: Day[] = [
  { label: "Sunday", index: 0 },
  { label: "Monday", index: 1 },
  { label: "Tuesday", index: 2 },
  { label: "Wednesday", index: 3 },
  { label: "Thursday", index: 4 },
  { label: "Friday", index: 5 },
  { label: "Saturday", index: 6 },
];

const initialEmployeeActivity: EmployeeAvailability[] = [
  { dayIndex: 0, available: true },
  { dayIndex: 1, available: true },
  { dayIndex: 2, available: true },
  { dayIndex: 3, available: true },
  { dayIndex: 4, available: true },
  { dayIndex: 5, available: true },
  { dayIndex: 6, available: true },
];

const MyAvailability = (): JSX.Element => {
  const { Title, Text } = Typography;
  const { selectedCompany } = useContext(CompanyContext);
  const employeeOps = useMemo(() => new EmployeeOperation(), []);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const [employeeActivity, setEmployeeActivity] = useState<
    EmployeeAvailability[]
  >(initialEmployeeActivity);

  const DayPreference: React.FC<{
    day: Day;
    available: boolean;
    onChange: (index: number, available: boolean) => void;
  }> = ({ day, available, onChange }) => {
    const value = available ? "On" : "Off";

    return (
      <Col span={20} xs={16} sm={20} lg={20} xl={22}>
        <Radio.Group
          value={value}
          size="middle"
          onChange={(e) => onChange(day.index, e.target.value === "On")}
        >
          <Radio.Button value="On">On</Radio.Button>
          <Radio.Button value="Off">Off</Radio.Button>
        </Radio.Group>
      </Col>
    );
  };

  const handleChange = (dayIndex: number, available: boolean) => {
    // Make the API call here and only update the local model
    // if it was successful.
    employeeOps
      .patchEmployeeAvailabilityAsync(
        selectedCompany?.corporationId as number,
        [
          {
            operationType: 2,
            op: "Replace",
            value: {
              dayIndex: dayIndex,
              available: available,
            },
          },
        ]
      )
      .then((x) => {
        if (x.statusCode == 200) {
          message.success("Successfully processed your request.");
          setEmployeeActivity((prev) =>
            prev.map((activity) =>
              activity.dayIndex === dayIndex
                ? { ...activity, available }
                : activity
            )
          );
        } else {
          message.error("There was an error trying to process your request!");
        }
      });
  };

  useMemo(() => {
    employeeOps
      .getEmployeeAvailabilityAsync(
        selectedCompany?.corporationId as number,
        userInfo?.employeeCorporation
          ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
          .map((x) => x.employeeId)
          .at(0) as string
      )
      .then((x) => {
        if (
          x.statusCode === 200 &&
          Array.isArray(x.data) &&
          x.data.length > 0
        ) {
          //Mapping Over Previous State: Instead of completely replacing the state, we're using prev.map to iterate over the existing employeeActivity.
          //Finding New Data: For each item in the previous state, we look for corresponding new data using find.
          //Updating Availability: If new data is found, we merge it with the existing item using the spread operator. If not, we return the existing item unchanged.
          setEmployeeActivity((prev) =>
            prev.map((item) => {
              const newData = x.data?.find((y) => y.dayIndex === item.dayIndex);
              return newData ? { ...item, available: newData.available } : item;
            })
          );
        }
      });
  }, [employeeOps, selectedCompany]);

  return (
    <div style={{ margin: "44px" }}>
      <Row gutter={[16, 24]}>
        <Col lg={18} md={24} xs={24}>
          <Title level={2}>Scheduling Preferences!</Title>
          <Alert
            message="Note: Selecting preferences does not guarantee that they will be honored."
            type="info"
            showIcon
          />
          <br />
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col
          span={12}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 18, order: 0 }}
          xl={{ span: 18, order: 0 }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Card title="Days Of The Week" size="default">
              <Row gutter={[16, 16]}>
                <Col span={1}></Col>
                <Col span={23}>
                  <Text style={{ textAlign: "center" }}>
                    <b>ON</b> - Prefer to work <b>OFF</b> - Prefer to be off
                    work
                  </Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
                {daysOfWeek.map((day) => (
                  <React.Fragment key={day.index}>
                    <Col span={4} xs={8} sm={4} lg={4} xl={2}>
                      <p>{day.label}</p>
                    </Col>
                    <DayPreference
                      day={day}
                      available={
                        employeeActivity[day.index]?.available || false
                      }
                      onChange={handleChange}
                    />
                  </React.Fragment>
                ))}
              </Row>
            </Card>
          </Space>
        </Col>
        <Col
          span={12}
          xs={{ span: 0, order: 0 }}
          sm={{ span: 0, order: 0 }}
          md={{ span: 0, order: 0 }}
          lg={{ span: 6, order: 2 }}
          xl={{ span: 6, order: 2 }}
        />
      </Row>
    </div>
  );
};

export default MyAvailability;
