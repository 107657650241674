import { Alert, Modal } from "antd";
import { useContext, useState } from "react";
import { timeOffStatus } from "../../../constants";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CompanyContext } from "../../../contexts/company-context";
import { TimeOffOperation } from "../../../serviceoperation/TimeoffOperation";

interface TimeOffRequestCancelProps {
  timeOffId: string;
  open: boolean;
  onCreate: () => void;
  onCancel: () => void;
}

const TimeOffRequestCancel: React.FC<TimeOffRequestCancelProps> = ({
  timeOffId,
  open,
  onCreate,
  onCancel,
}) => {
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const { selectedCompany } = useContext(CompanyContext);
  const [showError, setShowError] = useState(false);

  // Following is set for TimeOff Request Model.
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleTimeOffOk = async (): Promise<boolean> => {
    let success = false;
    const timeOffOps = new TimeOffOperation();
    await timeOffOps
      .postScheduleTimeOffApprovalAsync(
        selectedCompany?.corporationId as number,
        timeOffId,
        timeOffStatus.CANCEL
      )
      .then((value) => {
        if (value.statusCode === 200) {
          success = true;
        } else {
          setShowError(true);
        }
      })
      .catch(() => {
        setShowError(true);
      });
    return success;
  };

  return (
    <Modal
      title="Are you sure you want to cancel your TimeOff Request?"
      open={open}
      onOk={async () => {
        setConfirmLoading(true);
        if (await handleTimeOffOk()) {
          onCreate();
        } else {
          setShowError(true);
          setConfirmLoading(false);
        }
        setConfirmLoading(false);
      }}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
    >
      {showError ? (
        <Alert
          message="There was an error processing your request. Please try again later."
          type="error"
          showIcon
        />
      ) : null}
    </Modal>
  );
};

export default TimeOffRequestCancel;
